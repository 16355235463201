import React from 'react'
import down from '../../img/down.png'
import right from '../../img/right.png'

const DashboradOld = () => {
    const data = [
        { email: 'jebastin@gmail.com', date: '12/7/2024', startTime: '04:25 am', duration: '30 mins' },
        { email: 'prakash@gmail.com', date: '22/10/2024', startTime: '05:30 am', duration: '30 mins' },
        { email: 'samprabin@gmail.com', date: '08/07/2024', startTime: '04:25 am', duration: '30 mins' },
        { email: 'thanigaivel@gmail.com', date: '12/2/2024', startTime: '04:25 am', duration: '30 mins' },
        { email: 'arunk@gmail.com', date: '26/5/2024', startTime: '04:25 am', duration: '30 mins' },
    ];

    return (
        <main className='tw-bg-[#0036c2] tw-text-white tw-pb-20 tw-px-10 tw-pt-10 poppins'>



            <section className='tw-flex tw-justify-between tw-p-5 tw-gap-x-5  tw-rounded-lg'>

                <main className='tw-w-[20%] tw-bg-[#002fa8] tw-pt-10 tw-pl-10 tw-font-bold tw-pb-40 tw-rounded-md tw-mt-10'>


                    <div className='tw-mt-10 tw-flex tw-items-center tw-gap-x-10'>
                        <p className='tw-w-28'>Interview</p>
                        <img src={right} alt="" className='tw-w-5' />
                    </div>


                    <div className='tw-mt-10 tw-flex tw-items-center tw-gap-x-10'>
                        <p className='tw-w-28'>Candidate</p>
                        <img src={down} alt="" className='tw-w-5' />
                    </div>
                </main>

                <main className='tw-w-[80%] '>
                    <p className='tw-font-bold tw-text-6xl'>Upcoming interviews</p>
                    <p className='tw-mt-5 tw-font-medium tw-text-xl'>They are currently 12 upcoming interviews</p>



                    <table className="tw-w-full  tw-border-separate tw-border-spacing-y-4 tw-bg-[#002fa8] tw-mt-20">
                        <thead>
                            <tr className="tw-text-blue-300 tw-font-bold tw-text-center">
                                <th className="tw-py-2">Email</th>
                                <th className="tw-py-2">Date</th>
                                <th className="tw-py-2">Start Time</th>
                                <th className="tw-py-2">Duration</th>
                            </tr>
                        </thead>
                        <tbody className="tw-text-white " >
                            {data.map((row, index) => (
                                <tr key={index} className="tw-bg-[#002992] tw-rounded-md">
                                    <td className="tw-py-3 tw-px-4 tw-rounded-l-lg">{row.email}</td>
                                    <td className="tw-py-3 tw-px-4">{row.date}</td>
                                    <td className="tw-py-3 tw-px-4">{row.startTime}</td>
                                    <td className="tw-py-3 tw-px-4">{row.duration}</td>
                                    <td className="tw-py-3 tw-px-4 tw-text-center">
                                        <p className='tw-border-2 tw-border-red-50 tw-py-1 tw-px-4 tw-rounded-xl'>View Details </p>
                                    </td>
                                    <td className="tw-py-3 tw-px-4 tw-text-center tw-rounded-r-lg">
                                        <p className='tw-border-2 tw-border-red-50 tw-py-1 tw-px-4 tw-rounded-xl'>View Questions </p>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </main>

            </section>


        </main>
    )
}

export default DashboradOld