import { Value } from 'pdfjs-dist/build/pdf.worker';
import React, { useState } from 'react';
import { useForm } from "react-hook-form";


//import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
const SpeechRecognition =
    window.SpeechRecognition || window.webkitSpeechRecognition;

var current, transcript, upperCase;



const SpeechTest = () => {
    // const {
    //     transcript,
    //     listening,
    //     resetTranscript,
    //     browserSupportsSpeechRecognition
    // } = useSpeechRecognition();

    // if (!browserSupportsSpeechRecognition) {
    //     return <span>Browser doesn't support speech recognition.</span>;
    // }

    // return (
    //     <div>
    //         <p>Microphone: {listening ? 'on' : 'off'}</p>
    //         <button onClick={() => SpeechRecognition.startListening({ continuous: true })}>Start</button>
    //         <button onClick={SpeechRecognition.stopListening}>Stop</button>
    //         <button onClick={resetTranscript}>Reset</button>
    //         <p>{transcript}</p>
    //     </div>
    // );

    const { register, handleSubmit, setValue, reset, clearErrors, watch, formState: { errors } } = useForm();

    const [text, setText] = useState('');
    const [currentRecognition, setCurrentRecognition] = useState(null);
    const startRecord = (e) => {
        const recognition = new SpeechRecognition();
        setCurrentRecognition(recognition);
        recognition.continuous = true;
        recognition.lang = "en-US";
        recognition.interimResults = false;
        recognition.maxAlternatives = 1;
        recognition.start(e);
        recognition.onresult = (e) => {
            current = e.resultIndex;
            transcript = e.results[current][0].transcript;
            upperCase = transcript.charAt(0).toUpperCase() + transcript.substring(1);
            console.log("voice event", e);
            console.log("transcript", transcript);
            if (transcript !== undefined || transcript !== 'undefined') {
                setText(valtext => valtext + transcript);
            }

        };
        recognition.onend = (e) => {
            console.log("Recognition ended--------->", e)
        }
        recognition.onerror = (e) => {
            console.log("Recognition error--------->", e)
        }
    };

    const handleChange = (e) => {
        console.log("EEEE------------>", currentRecognition)
        const value = e.target.value;
        setText(value);
    }

    return (
        <div>
            <button onClick={(e) => startRecord(e)}>Click to speak</button>
            <div>Transcript: {text}</div>
            <textarea value={text} onChange={(e) => handleChange(e)} className="tw-block tw-p-2.5 tw-w-full tw-text-sm tw-text-gray-900 tw-bg-gray-50 tw-rounded-lg border tw-border-gray-300 focus:tw-ring-blue-500 focus:tw-border-blue-500">

            </textarea>

        </div>
    );
}

export default SpeechTest