import React, { useEffect, useState } from 'react'
import SideNavBar from '../../layout/SideNavBar'
import ai from '../../img/new/ai.svg'
import newInterview from '../../img/new/new-interview-flow.svg'
import totalInterviews from '../../img/new/total-interviews.svg'
import incompleted from '../../img/new/incompleted.svg'
import completed from '../../img/new/completed.svg'
import initial from '../../img/new/initial-screening.svg'
import final from '../../img/new/final-round.svg'
import technical from '../../img/new/technical-round.svg'
import hiringManager from '../../img/new/custom-ai-hiring-manager.svg'
import { FaArrowRight } from "react-icons/fa6";
import axios from 'axios'
import Loader from '../../layout/Loader'
import { toast } from 'react-toastify'
import AIPopup from '../Common/AIPopup'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const Dashboard = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const [total_interview, setTotalInterview] = useState(0);
  const [complete_interview, setCompleteInterview] = useState(0);
  const [incomplete_interview, setIncompleteInterview] = useState(0);
  const [userPrompts, setUserPrompts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const res = await axios.post('https://api.jarsis.ai/user/getUserDetails', {}, {
          headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' }
        })
        if (res.data.success === true) {
          const { payload } = res?.data;
          const { interviewsCount, userAIPrompts } = payload;
          const { completedInterviews, inCompleteInterviews, totalInterviews } = interviewsCount;

          setTotalInterview(totalInterviews);
          setCompleteInterview(completedInterviews);
          setIncompleteInterview(inCompleteInterviews);
          setUserPrompts(userAIPrompts)
        } else {
          setTotalInterview(0);
          setCompleteInterview(0);
          setIncompleteInterview(0);
        }
        setLoading(false)
      } catch (e) {
        setTotalInterview(0);
        setCompleteInterview(0);
        setIncompleteInterview(0);
        setLoading(false)
      }
    }
    fetchData();
  }, [])


  useEffect(() => {

  }, [])


  const handlingAddInterview = async (e) => {

    let role = e.name

    setLoading(true)
    handleCloseForm();
    try {
      const res = await axios.post('https://api.jarsis.ai/interviewQuestion/createInterviewQuestion', { role }, {
        headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' }
      })
      if (res.data.success === true) {
        const { createdInterviewQuestion } = res?.data?.payload
        localStorage.setItem("newai", createdInterviewQuestion?._id)

        toast.success("Please start to configure your AI")

      }
      setLoading(false)

      window.location.href = '/aiconfigure'
      //window.location.reload();

    } catch (error) {
      setLoading(false)
      toast.error(error.response.data.message)

      console.log(error);

    }
  }

  const handleCloseForm = () => {
    setShowPopup(!showPopup)
  }

  const gotoAIINterviewPage = (id) => {
    localStorage.setItem("newai", id)
    window.location.href = '/aiconfigure'
  }

  return (
    <main className='tw-bg-[#fdfdff] lg:tw-flex tw-gap-x-10 poppins lg:tw-mt-6 lg:tw-mx-6 tw-items-start tw-pb-5'>
      <AIPopup handleCloseForm={handleCloseForm} showForm={showPopup} handlingAddInterview={handlingAddInterview} />
      {/* left side menu bar */}

      <div hidden={!loading} className='tw-bg-black tw-bg-opacity-50 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-[999]'>
        <Loader />
      </div>

      <SideNavBar />

      {/* right side - main content */}
      <main className='lg:tw-w-[80%] tw-border tw-shadow-sm tw-rounded-2xl tw-px-5 tw-pt-5'>

        <section className='tw-flex tw-items-center tw-justify-between'>
          <p>Dashboard</p>
          <button onClick={() => handleCloseForm()} className=' tw-bg-black tw-text-white tw-flex tw-items-center tw-px-4 tw-py-2 tw-rounded-lg tw-gap-x-2'>
            <p>Create AI</p>
            <img src={ai} alt="" />
          </button>
        </section>

        {/* 3 color box */}
        <section className='tw-grid md:tw-grid-cols-3 tw-gap-6 tw-mt-10'>

          <main className='tw-bg-[#ecf0ff] tw-flex tw-items-center tw-p-5 tw-gap-x-5 tw-rounded-2xl tw-cursor-pointer' onClick={() => window.location.href = '/candidatelist'}>
            <div className='tw-bg-[#b4b4ff] tw-p-4 tw-rounded-xl'>
              <img src={totalInterviews} alt="" className='tw-w-6' />
            </div>
            <div className=''>
              <p className='tw-text-[#111111] tw-font-medium tw-text-3xl'>{total_interview}</p>
              <p className='tw-text-[#6d6d6d] tw-text-sm'>Total Interviews</p>
            </div>

          </main>

          <main className='tw-bg-[#e8ffe5] tw-flex tw-items-center tw-p-5 tw-gap-x-5 tw-rounded-2xl tw-cursor-pointer' onClick={() => window.location.href = '/candidatelist'}>
            <div className='tw-bg-[#b4deaf] tw-p-4 tw-rounded-xl'>
              <img src={completed} alt="" className='tw-w-6' />
            </div>
            <div className=''>
              <p className='tw-text-[#111111] tw-font-medium tw-text-3xl'>{complete_interview}</p>
              <p className='tw-text-[#6d6d6d] tw-text-sm'>Completed Interviews</p>
            </div>

          </main>

          <main className='tw-bg-[#fff7e0] tw-flex tw-items-center tw-p-5 tw-gap-x-5 tw-rounded-2xl tw-cursor-pointer' onClick={() => window.location.href = '/candidatelist'}>
            <div className='tw-bg-[#f2da95] tw-p-4 tw-rounded-xl'>
              <img src={incompleted} alt="" className='tw-w-6' />
            </div>
            <div className=''>
              <p className='tw-text-[#111111] tw-font-medium tw-text-3xl'>{incomplete_interview}</p>
              <p className='tw-text-[#6d6d6d] tw-text-sm'>Incompleted Interviews</p>
            </div>

          </main>

        </section>

        {/* Divider  */}
        <div className="tw-flex tw-items-center tw-justify-center tw-w-full tw-my-12">
          <span className="tw-mr-4 tw-text-[#6d6d6d] tw-text-sm tw-font-medium">Pre-build Al Hiring Manager</span>
          <div className="tw-flex-grow tw-border-t tw-border-[#E8E8FA]"></div>
        </div>

        {/* 3 box section */}


        {/* <section className='tw-grid tw-grid-cols-3 tw-gap-x-5 tw-mt-10'> */}
        <section className='tw-mt-10 tw-mb-10'>

          <Carousel responsive={responsive}>

            {userPrompts.length !== 0 && userPrompts.map((val, index) => {
              const prompt = val?.prompt ? val?.prompt.substring(0, 200) + "..." : "";
              return <div key={"sec" + index} className='tw-border tw-rounded-2xl tw-p-5 tw-pb-20 tw-relative tw-mr-5 tw-h-[250px]'>
                <img src={index % 2 === 0 ? initial : technical} alt="" />
                <p className='tw-font-semibold  tw-my-3'>{val?.role}</p>
                <p className='tw-text-[#6d6d6d] tw-text-sm tw-text-justify'>{prompt}</p>
                <div className='tw-mt-5 tw-flex tw-items-center tw-gap-x-2 tw-absolute tw-bottom-5'>
                  <p className='tw-text-[#3737FF] tw-cursor-pointer' onClick={() => gotoAIINterviewPage(val?.id)}>Start Al Screening</p>
                  <FaArrowRight className='tw-text-[#3737FF]' />

                </div>
              </div>
            })}

            {/* <div className='tw-border tw-rounded-2xl tw-p-5 tw-pb-20 tw-relative tw-mr-5'>
              <img src={initial} alt="" />
              <p className='tw-font-semibold  tw-my-3'>Initial Screening with Al</p>
              <p className='tw-text-[#6d6d6d] tw-text-sm tw-text-justify'>Maddy helps you to do initial Preliminary screening with generic questions, collects information and scores candidates based on Preliminary questions, vocabulary, etc. </p>
              <div className='tw-mt-5 tw-flex tw-items-center tw-gap-x-2 tw-absolute tw-bottom-5'>
                <p className='tw-text-[#3737FF]'>Start Al Screening</p>
                <FaArrowRight className='tw-text-[#3737FF]' />

              </div>
            </div>

            <div className='tw-border tw-rounded-2xl tw-p-5 tw-pb-20 tw-relative tw-mr-5'>
              <img src={technical} alt="" />
              <p className='tw-font-semibold  tw-my-3'>Technical Round with Al</p>
              <p className='tw-text-[#6d6d6d] tw-text-sm tw-text-justify'>David helps you to do a in depth process and technical questions to score a candidate and out them through some scenarios to score them on psychometric tests etc. </p>
              <div className='tw-mt-5 tw-flex tw-items-center tw-gap-x-2 tw-absolute tw-bottom-5'>
                <p className='tw-text-[#3737FF]'>Start Al Technical Test</p>
                <FaArrowRight className='tw-text-[#3737FF]' />
              </div>
            </div>

            <div className='tw-border tw-rounded-2xl tw-p-5 tw-pb-20 tw-relative tw-mr-5'>
              <img src={final} alt="" />
              <p className='tw-font-semibold  tw-my-3'>Final Interview with Al</p>
              <p className='tw-text-[#6d6d6d] tw-text-sm tw-text-justify'>Robert will your last Al to interview a candidate to discuss and explain the hiring process, roles, compensations, answer any final questions and close off the discussion.</p>
              <div className='tw-mt-5 tw-flex tw-items-center tw-gap-x-2 tw-absolute tw-bottom-5'>
                <p className='tw-text-[#3737FF]'>Start Al Final Interview</p>
                <FaArrowRight className='tw-text-[#3737FF]' />
              </div>
            </div>
            <div className='tw-border tw-rounded-2xl tw-p-5 tw-pb-20 tw-relative tw-mr-5'>
              <img src={final} alt="" />
              <p className='tw-font-semibold  tw-my-3'>Final Interview with Al</p>
              <p className='tw-text-[#6d6d6d] tw-text-sm tw-text-justify'>Robert will your last Al to interview a candidate to discuss and explain the hiring process, roles, compensations, answer any final questions and close off the discussion.</p>
              <div className='tw-mt-5 tw-flex tw-items-center tw-gap-x-2 tw-absolute tw-bottom-5'>
                <p className='tw-text-[#3737FF]'>Start Al Final Interview</p>
                <FaArrowRight className='tw-text-[#3737FF]' />
              </div>
            </div> */}
          </Carousel>
        </section>

        {/* Divider  */}
        {/* <div className="tw-flex tw-items-center tw-justify-center tw-w-full tw-my-12">
          <span className="tw-mr-4 tw-text-[#6d6d6d] tw-text-sm tw-font-medium">Custom Al Hiring Manager</span>
          <div className="tw-flex-grow tw-border-t tw-border-[#E8E8FA]"></div>
        </div> */}

        {/* 2 box section */}
        {/* <section className='tw-grid tw-grid-cols-3 tw-gap-x-5 tw-mt-10 tw-mb-10'>

          <main className='tw-border tw-rounded-2xl tw-p-5 tw-pb-20 tw-relative'>
            <img src={hiringManager} alt="" />
            <p className='tw-font-semibold  tw-my-3'>Process Interview</p>
            <p className='tw-text-[#6d6d6d] tw-text-sm tw-text-justify'>Maddy helps you to do initial Preliminary screening with generic questions, collects information and scores candidates based on Preliminary questions, vocabulary, etc. </p>
            <div className='tw-mt-5 tw-flex tw-items-center tw-gap-x-2 tw-absolute tw-bottom-5'>
              <p className='tw-text-[#3737FF]'>Start Al Screening</p>
              <FaArrowRight className='tw-text-[#3737FF]' />

            </div>
          </main>

          <main className='tw-border tw-border-dashed tw-border-black tw-rounded-2xl tw-flex tw-flex-col tw-justify-center tw-items-center'>
            <img src={newInterview} alt="" />
            <p className='tw-font-medium  tw-my-3'>New Interview Flow</p>


          </main>



        </section> */}

      </main>

    </main >

  )
}

export default Dashboard