import { useEffect, useRef, useState } from "react";
import * as pdfjs from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.entry";

const PdfViewer = ({ pdfUrl }) => {
    const [numPages, setNumPages] = useState(0);
    const containerRef = useRef(null);

    useEffect(() => {
        const loadPdf = async () => {
            try {
                const loadingTask = pdfjs.getDocument(pdfUrl);
                const pdf = await loadingTask.promise;
                setNumPages(pdf.numPages); // Set total pages

                for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
                    const page = await pdf.getPage(pageNumber);
                    const viewport = page.getViewport({ scale: 1.5 });

                    // Create a new canvas for each page
                    const canvas = document.createElement("canvas");
                    canvas.width = viewport.width;
                    canvas.height = viewport.height;
                    containerRef.current.appendChild(canvas);

                    const context = canvas.getContext("2d");
                    const renderContext = { canvasContext: context, viewport };
                    page.render(renderContext);
                }
            } catch (error) {
                console.error("Error loading PDF:", error);
            }
        };

        loadPdf();
    }, [pdfUrl]);

    return <div ref={containerRef} style={{ display: "flex", flexDirection: "column", gap: "10px" }} />;
};

export default PdfViewer;
