import "./App.css";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import Home from "./components/home/Home";
import Signup from "./components/signup/Signup";
import Login from "./components/login/Login";
import Interview from "./components/interview/Interview";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewDetails from "./components/viewDetails/ViewDetails";
import Dashboard from "./components/dashboard/Dashboard";
import DashboardOld from "./components/dashboard/DashboradOld";
import Aiconfigurenew from "./components/aiconfigurenew/Aiconfigurenew";
import FlowDiagram from "./components/aiconfigurenew/Testchart";
import AiInterviewConfigure from "./components/AiInterviewConfigure/AiInterviewConfigure";
import D from "./components/D";
import UpdateNode from "./components/aiconfigurenew/Testnode";
import OriginalChart from "./components/aiconfigurenew/OriginalChart";
import SequenceDiagram from "./components/aiconfigurenew/ReactflowRender";
import Test from "./components/AiInterviewConfigure/Test";
import LandingPage from "./components/Landing Page/LandingPage";
import Landingnew from "./components/Landing Page/Landingnew";
import Settings from "./components/Settings/Settings";
import CandidateList from "./components/candidateList/CandidateList";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import Otp from "./components/ForgotPassword/Otp";
import ResetPassword from "./components/ForgotPassword/ResetPassword";
import PitchToDoctorAi from "./components/TrainingAI/PitchToDoctorAi";
import TraineeList from "./components/TrainingAI/TraineeList";
import SuperAdmin from "./components/superAdmin/SuperAdmin";
import AIList from "./components/superAdmin/AIList";
import AllInterviews from "./components/superAdmin/AllInterviews";
import Chart from "./components/superAdmin/Chart";
import ResumeUpload from "./components/resumeUpload/ResumeUpload";
import ResumeParser from "./components/resumeUpload/ResumeTest";
import SpeechTest from "./components/AiInterviewConfigure/SpeechTest";

// thanigaivel23yaazh@gmail.com
// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY3MmIxN2NkNDdhNjQ5MDg3ZGFkNGExZSIsImlhdCI6MTczNjQxNTcxNiwiZXhwIjoxNzM3MDIwNTE2fQ.WyNGxYeQBa26ZkTb7XdfWVeTfCICs3pfI8LeWNjEZD8

function App() {
  const AuthGuard = (props) => {
    const auth = (localStorage.getItem('usertoken') != null) ? true : null;

    // If has token, return outlet in other case return navigate to login page

    return auth ? props.Component : <Navigate to="/" />;
  }

  return (
    <>
      <BrowserRouter>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Routes>
          {/* responsive */}
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/aiconfigure" element={<AiInterviewConfigure />} />
          <Route path="/landing" element={<AuthGuard Component={<LandingPage />} />} />
          <Route path="/candidatelist" element={<CandidateList />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/settings" element={<AuthGuard Component={<Settings />} />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/PitchToAiDoctor" element={<AuthGuard Component={<PitchToDoctorAi />} />} />
          <Route path="/TraineeList" element={<AuthGuard Component={<TraineeList />} />} />
          <Route path="/super_admin" element={<AuthGuard Component={<SuperAdmin />} />} />
          <Route path="/aiList" element={<AuthGuard Component={<AIList />} />} />
          <Route path="/super_admin/interviews" element={<AuthGuard Component={<AllInterviews />} />} />
          <Route path="/super_admin/interviews/chart" element={<AuthGuard Component={<Chart />} />} />

          {/* non-responsive */}
          <Route path="/home" element={<AuthGuard Component={<Home />} />} />
          <Route path="/interview" element={<Interview />} />
          <Route path="/viewDetails" element={<ViewDetails />} />
          <Route path="/dashboardold" element={<DashboardOld />} />
          <Route path="/aiconfigurenew" element={<Aiconfigurenew />} />
          <Route path="/test" element={<Test />} />
          <Route path="/d" element={<D />} />
          <Route path="/Test" element={<FlowDiagram />} />
          <Route path="/speechtest" element={<SpeechTest />} />
          <Route path="/Testnew" element={<UpdateNode />} />
          <Route path="/org" element={<OriginalChart />} />
          <Route path="/render" element={<SequenceDiagram />} />
          <Route path="/landingnew" element={<Landingnew />} />
          <Route path="/resume" element={<ResumeUpload />} />

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
