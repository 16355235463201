import React, { useState } from 'react'
import logo from '../../img/new/Jarsis.ai.png'
import dashboard from '../../img/new/dashboard.svg'
import dashboardActive from '../../img/new/dashboard-active.svg'

import trainee from '../../img/new/trainee-list.svg'
import traineeactive from '../../img/new/trainee-list-active.svg'

import logout from '../../img/new/logout.svg'
import { Link, useLocation } from 'react-router-dom'
import menu from '../../img/new/menu.png'
import Offcanvas from 'react-bootstrap/Offcanvas';


import intervieew from '../../img/new/intervieew.svg'
import intervieewactive from '../../img/new/intervieew-active.svg'

const SideNavBar = () => {

    const location = useLocation();


    // responsive 
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const navItems = (
        <>
            {/* interview ai */}
            <section>
                <p className='tw-text-[#5D5D5D] tw-text-sm tw-pt-10 tw-border-b tw-pb-2 tw-mb-5'>Admin</p>


                <Link to='/super_admin' className='tw-flex tw-gap-x-2 tw-items-center tw-my-3 tw-cursor-pointer tw-w-fit '>
                    {location.pathname === '/super_admin' ?
                        <>
                            <img src={dashboardActive} alt="" />
                            <p className='tw-text-[#3737FF]' >Dashboard</p>
                        </>
                        :

                        <>
                            <img src={dashboard} alt="" />
                            <p>Dashboard</p>
                        </>}
                </Link>


                <Link to='/aiList' className='tw-flex tw-gap-x-2 tw-items-center tw-my-3 tw-cursor-pointer tw-w-fit '>
                    {location.pathname === '/aiList' ?
                        <>
                            <img src={traineeactive} alt="" />
                            <p className='tw-text-[#3737FF]' >AI List</p>
                        </>
                        :

                        <>
                            <img src={trainee} alt="" />
                            <p>AI List</p>
                        </>}
                </Link>

                <Link to='/super_admin/interviews' className='tw-flex tw-gap-x-2 tw-items-center tw-my-3 tw-cursor-pointer tw-w-fit'>


                    {location.pathname === '/super_admin/interviews' ?
                        <>
                            <img src={intervieewactive} alt="" />
                            <p className='tw-text-[#3737FF]' >Interview</p>
                        </>
                        :

                        <>
                            <img src={intervieew} alt="" />
                            <p>Interview</p>
                        </>
                    }
                </Link>





            </section>

            <div onClick={() => { window.location.href = '/landing' }} className='tw-flex tw-gap-x-2 tw-items-center tw-mt-14 tw-mb-5 tw-cursor-pointer tw-w-fit '>
                <img src={logout} alt="" />
                <p>Home</p>
            </div>
        </>
    )



    return (
        <main className='tw-border tw-shadow-sm  lg:tw-rounded-3xl tw-px-5 tw-pt-5 tw-w-[100%] lg:tw-w-[16%] '>

            {/* title and logo */}
            <section className='tw-flex tw-gap-x-2 tw-items-center tw-justify-center lg:tw-justify-normal tw-pb-3 lg:tw-pb-0' >
                <img src={logo} alt="" className='tw-w-10 lg:tw-w-8 ' />
                <Link to={'/landing'} className='tw-font-[600] tw-text-lg lg:tw-text-sm'>jarsis.ai</Link>
                <img onClick={handleShow} src={menu} alt="" className='tw-absolute tw-left-3 tw-w-10 tw-cursor-pointer lg:tw-hidden' />
            </section>

            <Offcanvas show={show} onHide={handleClose} className='tw-w-[70%] md:tw-w-[40%] '>
                <Offcanvas.Header className='tw-flex tw-justify-end tw-pr-5 tw-pt-5' closeButton>

                </Offcanvas.Header>
                <Offcanvas.Body>
                    {navItems}
                </Offcanvas.Body>
            </Offcanvas>

            <main className='tw-hidden lg:tw-inline'>
                {navItems}
            </main>



        </main>
    )
}

export default SideNavBar