import React, { useRef, useState } from 'react';
import ReactFlow, { Background, Controls } from 'reactflow';
import 'reactflow/dist/style.css';

const CustomNode = ({ data }) => {
    return (
        <div style={{ textAlign: 'center', position: 'relative' }}>
            {/* Node Title */}
            {data.title && (
                <div
                    style={{
                        fontWeight: 'bold',
                        fontSize: '10px',
                        marginBottom: '10px',
                        color: '#333',
                    }}
                >
                    {data.title}
                </div>
            )}
            {/* Node Content */}
            <div
                style={{
                    background: '#cce7ff',
                    padding: '10px',
                    borderRadius: '5px',
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                }}
            >
                {data.label}
            </div>
        </div>
    );
};

const OriginalChart = () => {

    const [nodes, setNodes] = useState([]);

    const [edges, setEdges] = useState([]);

    const [isSelectedNode, setIsSelectedNode] = useState("")

    const nodeTitleRef = useRef(null);
    const nodeNameRef = useRef(null);
    const questionRef = useRef(null);

    const addNode = () => {
        const title = nodeTitleRef.current.value;
        const name = nodeNameRef.current.value;
        console.log("isSelectedNode--------->", isSelectedNode)

        if (isSelectedNode !== "") {
            setNodes((nds) =>
                nds.map((node) => {
                    if (node.id === isSelectedNode) {
                        // it's important that you create a new node object
                        // in order to notify react flow about the change
                        return {
                            ...node,
                            data: {
                                ...node.data,
                                label: name,
                                title: title
                            },
                        };
                    }

                    return node;
                }),
            );

            nodeNameRef.current.value = null;
            nodeTitleRef.current.value = null;
            setIsSelectedNode("")
        } else {

            const newNode = {
                id: (nodes.length + 1).toString(),
                position: { x: 100, y: nodes.length * 100 },
                data: { label: `${name} `, title: title },
                //style: { background: '#e0f7fa', padding: 10 },
                type: "customNode"
            };

            const newEdge = {
                id: `e${nodes.length}-${nodes.length + 1}`,
                source: (nodes.length).toString(),
                target: (nodes.length + 1).toString(),
                animated: true,
            };

            console.log("newEdge--------->", newEdge)

            nodeNameRef.current.value = null;
            nodeTitleRef.current.value = null;

            setNodes((nds) => [...nds, newNode]);
            setEdges((eds) => [...eds, newEdge]);
        }
    };

    const nodeSelectHandler = (e, node) => {
        console.log("node--------->", nodes)
        nodeNameRef.current.value = node?.data?.label
        nodeTitleRef.current.value = node?.data?.title
        setIsSelectedNode(node?.id)
    }

    const addQuestions = () => {
        const title = nodeTitleRef.current.value;
        const name = questionRef.current.value;
        console.log("isSelectedNode--------->", isSelectedNode)

        // if (isSelectedNode !== "") {
        //     setNodes((nds) =>
        //         nds.map((node) => {
        //             if (node.id === isSelectedNode) {
        //                 // it's important that you create a new node object
        //                 // in order to notify react flow about the change
        //                 return {
        //                     ...node,
        //                     data: {
        //                         ...node.data,
        //                         label: name
        //                     },
        //                 };
        //             }

        //             return node;
        //         }),
        //     );

        //     nodeNameRef.current.value = null;
        //     nodeTitleRef.current.value = null;
        //     setIsSelectedNode("")
        // } else {

        var result = nodes.filter(obj => {
            console.log("obj------------>", obj)
            return obj.id === isSelectedNode
        })
        console.log("result------------>", result)
        const newNode = {
            id: `` + (nodes.length + 1).toString(),
            position: { x: result[0].position.x * 2, y: 30 },
            data: { label: `${name} ` },
            //style: { background: '#e0f7fa', padding: 10 },
        };

        const newEdge = {
            id: `e${nodes.length}-${nodes.length + 1}`,
            source: (nodes.length).toString(),
            target: (nodes.length + 1).toString(),
            animated: true,
        };

        console.log("newEdge--------->", newEdge)

        nodeNameRef.current.value = null;
        nodeTitleRef.current.value = null;

        setNodes((nds) => [...nds, newNode]);
        setEdges((eds) => [...eds, newEdge]);
        //   }
    };

    return (
        <div className='tw-p-10 tw-flex'>
            <div class="tw-bg-gray-100 tw-flex tw-justify-center tw-items-center tw-p-8 tw-w-[80%]">

                <div class="tw-flex tw-flex-col tw-items-center tw-space-y-8 tw-relative">
                    <div class="tw-w-64 tw-bg-red-300 tw-text-center tw-py-4 tw-rounded tw-shadow tw-font-bold">Start the Interview</div>
                    <div class="tw-h-8 tw-w-1 tw-bg-gray-500"></div>
                    <div id="dialog2" class="triangle_down1"></div>

                    <div style={{ height: '600px', width: '100%' }}>
                        <ReactFlow nodes={nodes} edges={edges} onNodeClick={(e, node) => nodeSelectHandler(e, node)} fitView nodeTypes={{ customNode: CustomNode }}>
                            <Background />
                            <Controls />
                        </ReactFlow>
                    </div>

                    <div class="tw-w-64 tw-bg-red-300 tw-text-center tw-py-4 tw-rounded tw-shadow tw-font-bold">End the Interview</div>
                </div>
            </div>
            <div className='tw-bg-gray-500 tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-8 tw-w-[20%]'>
                <div>
                    <input type="text" ref={nodeTitleRef} class="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-p-2.5 dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:focus:tw-ring-blue-500 dark:focus:tw-border-blue-500" placeholder="Node title" />
                </div>
                <div className='tw-mt-2'>
                    <input type="text" ref={nodeNameRef} class="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-p-2.5 dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:focus:tw-ring-blue-500 dark:focus:tw-border-blue-500" placeholder="Node name" />
                </div>
                <div className='tw-mt-2'>
                    {/* <button onClick={addNode} style={{ marginTop: 20 }}>
                        
                    </button> */}

                    <button type="button" onClick={addNode} class="tw-text-white tw-bg-blue-700 hover:tw-bg-blue-800 focus:tw-ring-4 focus:tw-ring-blue-300 tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-me-2 tw-mb-2 dark:tw-bg-blue-600 dark:hover:tw-bg-blue-700 focus:tw-outline-none dark:focus:tw-ring-blue-800">Add Node</button>
                </div>

                <div className='tw-mt-2'>
                    <input type="text" ref={questionRef} class="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-p-2.5 dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:focus:tw-ring-blue-500 dark:focus:tw-border-blue-500" placeholder="Node name" />
                </div>

                <div className='tw-mt-2'>
                    <button type="button" onClick={addQuestions} class="tw-text-white tw-bg-blue-700 hover:tw-bg-blue-800 focus:tw-ring-4 focus:tw-ring-blue-300 tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-me-2 tw-mb-2 dark:tw-bg-blue-600 dark:hover:tw-bg-blue-700 focus:tw-outline-none dark:focus:tw-ring-blue-800">Add Question</button>
                </div>

            </div>
        </div>
    )
}

export default OriginalChart