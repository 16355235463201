import React, { useEffect, useState } from 'react'

import ai from '../../img/new/ai.svg'
import profile from '../../img/new/profile-pic-dummy.svg'
import initial from '../../img/new/initial-screening.svg'
import final from '../../img/new/final-round.svg'
import technical from '../../img/new/technical-round.svg'
import { FaArrowRight } from "react-icons/fa6";
import SideNavBar from '../../layout/SideNavBar'
import AIPopup from '../Common/AIPopup'
import axios from 'axios'
import Loader from '../../layout/Loader'
import { toast } from 'react-toastify';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


const LandingPage = () => {

  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userPrompts, setUserPrompts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const res = await axios.post('https://api.jarsis.ai/user/getUserDetails', {}, {
          headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' }
        })
        if (res.data.success === true) {
          const { payload } = res?.data;
          const { interviewsCount, userAIPrompts, userDetails } = payload;
          const { completedInterviews, inCompleteInterviews, totalInterviews } = interviewsCount;
          localStorage.setItem("isAdmin", userDetails.isAdmin)

          setUserPrompts(userAIPrompts)
        } else {
          setUserPrompts([])
        }
        setLoading(false)
      } catch (e) {
        setUserPrompts([])
      }
    }
    fetchData();
  }, [])


  const handleCloseForm = () => {
    setShowPopup(!showPopup)
  }

  const handlingAddInterview = async (e) => {

    let role = e.name
    let language = e.language

    setLoading(true)
    handleCloseForm()
    try {
      const res = await axios.post('https://api.jarsis.ai/interviewQuestion/createInterviewQuestion', { role, language }, {
        headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' }
      })
      if (res.data.success === true) {
        const { createdInterviewQuestion } = res?.data?.payload
        localStorage.setItem("newai", createdInterviewQuestion?._id)

        toast.success("Please start to configure your AI")

      }
      setLoading(false)

      // window.location.href = '/aiconfigure'

      //window.location.reload();

    } catch (error) {
      setLoading(false)
      toast.error(error.response.data.message)

      console.log(error);

    }
  }

  const gotoAIINterviewPage = (id) => {
    localStorage.setItem("newai", id)
    window.location.href = '/aiconfigure'
  }

  return (
    <main className='tw-bg-[#fdfdff] lg:tw-flex tw-gap-x-10 poppins lg:tw-mt-6 lg:tw-mx-6 tw-items-start'>
      <div hidden={!loading} className='tw-bg-black tw-bg-opacity-20 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-[999]'>
        <Loader />
      </div>
      {/* left side menu bar */}
      <AIPopup handleCloseForm={handleCloseForm} showForm={showPopup} handlingAddInterview={handlingAddInterview} />

      <SideNavBar />

      {/* right side - main content */}
      <main className='tw-w-[100%] lg:tw-w-[81%] tw-px-10 lg:tw-px-32'>

        {/* main section */}
        <section className='tw-text-center'>
          <img src={profile} alt="" className='tw-mx-auto tw-mt-20' />
          <p className='tw-font-semibold tw-text-2xl tw-mt-7'>Custom AI Interviewer</p>
          <p className='tw-text-sm tw-text-[#6D6D6D] tw-mt-2'>Al for your recruitment process</p>

          <button onClick={() => handleCloseForm()} className='tw-mt-5 tw-bg-black tw-text-white tw-flex tw-items-center tw-mx-auto tw-px-4 tw-py-2 tw-rounded-lg tw-gap-x-2'>
            <p>Create New</p>
            <img src={ai} alt="" />
          </button>

        </section>

        {/* Divider  */}
        {userPrompts.length !== 0 && <div className="tw-flex tw-items-center tw-justify-center tw-w-full tw-mt-20">
          <div className="tw-flex-grow tw-border-t tw-border-[#E8E8FA]"></div>
          <span className="tw-mx-4 tw-text-[#6d6d6d] tw-text-sm tw-font-medium">OR</span>
          <div className="tw-flex-grow tw-border-t tw-border-[#E8E8FA]"></div>
        </div>}

        {userPrompts.length !== 0 && <p className='tw-text-center tw-text-[#6d6d6d] tw-text-sm tw-mt-20'>Choose from pre build templates of AI Hiring Managers</p>}

        {/* 3 box section */}

        {userPrompts.length !== 0 && <section className='tw-mt-10 tw-mb-10'>

          <Carousel responsive={responsive}>

            {userPrompts.length !== 0 && userPrompts.map((val, index) => {
              const prompt = val?.prompt ? val?.prompt.substring(0, 200) + "..." : "";
              return <div key={"sec" + index} className='tw-border tw-rounded-2xl tw-p-5 tw-pb-40 tw-relative tw-mr-5 tw-h-[290px] '>
                <img src={index % 2 === 0 ? initial : technical} alt="" />
                <p className='tw-font-semibold  tw-my-3'>{val?.role}</p>
                <p className='tw-text-[#6d6d6d] tw-text-sm tw-text-justify tw-mb-20'>{prompt}</p>
                <div className='tw-mt-5 tw-flex tw-items-center tw-gap-x-2 tw-absolute tw-bottom-5 '>
                  <p className='tw-text-[#3737FF] tw-cursor-pointer' onClick={() => gotoAIINterviewPage(val?.id)}>Get Started</p>
                  <FaArrowRight className='tw-text-[#3737FF]' />

                </div>
              </div>
            })}
          </Carousel>
        </section>}
        {/* <section className='tw-grid tw-grid-cols-3 tw-gap-x-5 tw-mt-10 tw-mb-40 '>
          <main className='tw-border tw-rounded-2xl tw-p-5 tw-pb-14 tw-relative'>
            <img src={initial} alt="" />
            <p className='tw-font-semibold tw-my-3'>Initial Screening with Al</p>
            <p className='tw-text-[#6d6d6d] tw-text-sm'>Maddy helps you to do initial Preliminary screening with generic questions, collects information and scores candidates based on Preliminary questions, vocabulary, etc. </p>
            <div className='tw-mt-5 tw-absolute tw-bottom-5 tw-flex tw-items-center tw-gap-x-2 '>
              <p className='tw-text-[#3737FF]'>Get Started</p>
              <FaArrowRight className='tw-text-[#3737FF]' />

            </div>
          </main>

          <main className='tw-border tw-rounded-2xl tw-p-5 tw-pb-14 tw-relative'>
            <img src={technical} alt="" />
            <p className='tw-font-semibold tw-my-3'>Technical Round with Al</p>
            <p className='tw-text-[#6d6d6d] tw-text-sm'>David helps you to do a in depth process and technical questions to score a candidate and out them through some scenarios to score them on psychometric tests etc. </p>
            <div className='tw-mt-5 tw-absolute tw-bottom-5 tw-flex tw-items-center tw-gap-x-2'>
              <p className='tw-text-[#3737FF]'>Get Started</p>
              <FaArrowRight className='tw-text-[#3737FF]' />
            </div>
          </main>

          <main className='tw-border tw-rounded-2xl tw-p-5 tw-pb-14 tw-relative'>
            <img src={final} alt="" />
            <p className='tw-font-semibold tw-my-3'>Final Interview with Al</p>
            <p className='tw-text-[#6d6d6d] tw-text-sm'>Robert will your last Al to interview a candidate to discuss and explain the hiring process, roles, compensations, answer any final questions and close off the discussion.</p>
            <div className='tw-mt-5 tw-absolute tw-bottom-5 tw-flex tw-items-center tw-gap-x-2 '>
              <p className='tw-text-[#3737FF]'>Get Started</p>
              <FaArrowRight className='tw-text-[#3737FF]' />
            </div>
          </main>
        </section> */}

      </main>

    </main >
  )
}

export default LandingPage