import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";

const InputForm = (props) => {
    // const [formData, setFormData] = useState(props?.formData);
    // const [showError, setShowError] = useState(false);
    //console.log("props------>", props)

    const { showForm, handleCloseForm, savePromptConfiguration, formData } = props;

    const { register, handleSubmit, setValue, reset, clearErrors, watch, formState: { errors } } = useForm();


    const [aititle, setAiTitle] = useState("");
    const [aiprompt, setAiPrompt] = useState("");
    const [aiquestion, setAiQuestion] = useState("")


    const onSubmit = data => {
        savePromptConfiguration(data)
        reset();
        clearErrors();
        setValue("title", "")
        setValue("aiprompt", "")
        setValue("aiquestion", "")
    };

    useEffect(() => {
        reset();
        clearErrors();
        setAiTitle((typeof formData === 'string' || formData instanceof String) ? "" : formData.title);
        setAiPrompt((typeof formData === 'string' || formData instanceof String) ? "" : formData.aiprompt);
        setAiQuestion((typeof formData === 'string' || formData instanceof String) ? "" : formData.aiquestion);

        setValue("title", (typeof formData === 'string' || formData instanceof String) ? "" : formData.title)
        setValue("aiprompt", (typeof formData === 'string' || formData instanceof String) ? "" : formData.aiprompt)
        setValue("aiquestion", (typeof formData === 'string' || formData instanceof String) ? "" : formData.aiquestion)
    }, [formData])



    // useEffect(() => {
    //     setFormData(props?.formData)
    // }, [props?.formData])


    // const submitForm = () => {
    //     if (formData) {
    //         saveFormChanges(formData);
    //         handleCloseForm();
    //     } else {
    //         setShowError(true)
    //     }
    // }

    // const getFormData = () => {
    //     console.log("selectedItem------------>", selectedItem)
    //     if (selectedItem === 'ai') {
    //         return "AI Prompt";
    //     }
    //     if (selectedItem === 'emotional') {
    //         return "Emotional Prompt";
    //     }
    //     if (selectedItem === 'logical') {
    //         return "Logical Prompt";
    //     }
    //     return "Prompt";
    // }


    const saveChanges = () => {
        handleSubmit(onSubmit)
    }

    return (
        <Modal show={showForm} onHide={() => handleCloseForm()}>
            <Modal.Header closeButton>
                <Modal.Title>{(typeof formData === 'string' || formData instanceof String) ? formData : aititle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="hook-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="mt-14 tw-mx-auto p-20">
                        <input id="message" {...register("title", { required: true, value: aititle })} rows="4" className="tw-block tw-p-2.5 tw-w-full tw-text-sm tw-text-gray-900 tw-bg-gray-50 tw-rounded-lg border tw-border-gray-300 focus:tw-ring-blue-500 focus:tw-border-blue-500" placeholder="Title here..."></input>
                    </div>
                    {errors.title && <p className='tw-text-red-500'>Please enter prompt title</p>}

                    <div className="tw-mt-3 mx-auto p-20">
                        <textarea id="message" {...register("aiprompt", { required: true, value: aiprompt })} rows="4" className="tw-block tw-p-2.5 tw-w-full tw-text-sm tw-text-gray-900 tw-bg-gray-50 tw-rounded-lg border tw-border-gray-300 focus:tw-ring-blue-500 focus:tw-border-blue-500" placeholder="configure AI here..."></textarea>
                    </div>
                    {errors.aiprompt && <p className='tw-text-red-500'>Please enter your configuration</p>}

                    <div className="tw-mt-3 mx-auto p-20">
                        <textarea id="message" rows="4"  {...register("aiquestion", { value: aiquestion })} className="tw-block tw-p-2.5 tw-w-full tw-text-sm tw-text-gray-900 tw-bg-gray-50 tw-rounded-lg border tw-border-gray-300 focus:tw-ring-blue-500 focus:tw-border-blue-500" placeholder="Question..."></textarea>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => handleCloseForm()}>
                    Close
                </Button>
                <Button variant="primary" type='submit' form="hook-form">
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default InputForm