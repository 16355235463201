import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Accordion from 'react-bootstrap/Accordion';
import downarrow from '../../img/downarrow.png';
import rightarrow from '../../img/rightarrow.png';
import xmark from '../../img/xmark.png';
import InputForm from './inputForm';
import QuestionPopup from './QuestionPopup';
import { toast } from 'react-toastify';
import Loader from '../../layout/Loader';
import { parseWebStream } from 'music-metadata';

const Test = () => {

    const [levels, setLevels] = useState(["level1"])
    const [isupdated, setIsUpdated] = useState(false);
    const [interviewDetails, setInterviewDetails] = useState([])

    const [questions, setQuestions] = useState([]);

    const [promptValues, setPromptValues] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [selectedinteviewInd, setSelectedInterviewInd] = useState("")
    const [selectedpromptInd, setSelectedPromptInd] = useState("");
    const [selectedQuesInd, setSelectedQuesInd] = useState("");
    const [formData, setFormData] = useState("");

    const [showQuesForm, setShowQuesForm] = useState(false);
    const [loading, setLoading] = useState(true);


    // Function to add a new question
    const addPrompt = (index) => {
        const existVal = interviewDetails;
        const currentObj = existVal[index]

        const currentArry = currentObj.levels;
        currentArry.push(`Prompt ${currentArry.length + 1}`)
        // existVal[index] = { ...currentObj, currentArry }
        setInterviewDetails(existVal)
        //setQuestions([...questions, ""]);
        setIsUpdated(!isupdated)
    };

    const addQuestion = (intIndex, promptIndex) => {
        const existVal = interviewDetails;
        const currentObj = existVal[intIndex]

        const currentArry = currentObj.levels;
        console.log("currentArry----------->", currentArry[promptIndex])
        if (typeof currentArry[promptIndex] === 'string' || currentArry[promptIndex] instanceof String) {
            toast.error("Please configure AI before add questions")
        } else {
            currentArry[promptIndex].subQuestions = currentArry[promptIndex].subQuestions ? currentArry[promptIndex].subQuestions : []
            currentArry[promptIndex].subQuestions.push(`Question ${currentArry[promptIndex].subQuestions.length + 1}`)
            //existVal[intIndex] = { ...currentObj, currentArry }
            console.log("existVal----------->", existVal)
            setInterviewDetails(existVal)
            //setQuestions([...questions, ""]);
            setIsUpdated(!isupdated)
        }
    };

    // Function to handle input changes
    const handleInputChange = (index, value) => {
        const newQuestions = [...questions];
        newQuestions[index] = value;
        setQuestions(newQuestions);
    };

    useEffect(() => {

    }, [levels, isupdated])

    useEffect(() => {

        const getInterviewDetails = async () => {
            try {
                setLoading(true);
                const res = await axios.post('https://api.jarsis.ai/interviewQuestion/getAllInterviewQuestions', {}, { headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' } });
                const { data } = res;

                const { payload } = data;
                const { interviewQuestionDetails } = payload;
                interviewQuestionDetails.map(val => {
                    const existvalues = interviewDetails;
                    // existvalues[val?.role] = {}

                    val.levels = [];
                    existvalues.push(val)
                    setInterviewDetails(existvalues)
                    setIsUpdated(!isupdated)
                })
                setLoading(false);
            } catch (e) {
                toast.error("Something went wrong");
                setLoading(false);
            }
        }
        const fetchData = async () => {
            try {
                setLoading(true);
                const res = await axios.post('https://api.jarsis.ai/interview/getAllInterviewsByEmail', { isTraining: true, emailId: `${localStorage.getItem("user_emailId")}` }, { headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' } })

                if (res.data.success && res?.data?.payload?.interviewDetails.length !== 0) {


                    // const details = res?.data?.payload?.interviewDetails;
                    // console.log('details)', details);
                    const interviewDetails = res?.data?.payload?.interviewDetails
                    console.log("res----------->", interviewDetails);
                    interviewDetails.map(async details => {
                        console.log("res details----------->", details);
                        if (details?.interviewAudioSeparate && details?.interviewAudioSeparate.length !== 0) {
                            const audios = details?.interviewAudioSeparate;
                            const filteredAudios = audios.sort(({ sequenceNumber: a }, { sequenceNumber: b }) => a - b);
                            const humanAudios = [];
                            const aiAudios = []
                            const bothAiHumanAudios = []
                            if (filteredAudios.length !== 0) {
                                filteredAudios.map(msg => {
                                    if (msg.isHumanAudio) {
                                        humanAudios.push(msg.url)
                                    }
                                });

                                filteredAudios.map(msg => {
                                    if (!msg.isHumanAudio) {
                                        aiAudios.push(msg.url)
                                    }
                                });
                                filteredAudios.map(msg => {
                                    bothAiHumanAudios.push(msg.url)
                                });
                                console.log("bothAiHumanAudios>>>>>>>>>>", bothAiHumanAudios);
                                try {
                                    // Assuming you have a ReadableStream of an audio file
                                    const response = await fetch(bothAiHumanAudios[0]);
                                    console.log("metadata response>>>>>>>>>>", response);
                                    const webStream = response.body;

                                    // Parse the metadata from the web stream
                                    const metadata = await parseWebStream(webStream, 'audio/wav');

                                    // Log the parsed metadata
                                    console.log("metadata>>>>>>>>>>", metadata);
                                    return metadata?.format?.duration ? metadata?.format?.duration : 0;
                                } catch (error) {
                                    console.error('Error parsing metadata:', error.message);
                                    return 0;
                                }

                            }
                        }
                    })




                } else {

                }
                setLoading(false);
            } catch (e) {
                setLoading(false);

            }

        }
        fetchData();

        getInterviewDetails();

    }, [])

    const addLevels = (index) => {
        const count = interviewDetails[index].levels.length;
        console.log("count-------->", count)
        const existArray = interviewDetails;
        const existValues = existArray[index];
        existValues.levels.push("level" + (count + 1));
        console.log("count111-------->", existValues)
        existArray[index] = existValues
        setInterviewDetails(existArray);
        setIsUpdated(!isupdated)
    }

    const handleCloseForm = () => {
        setShowForm(false)
    }

    const handleShowForm = (intId, promId) => {
        setSelectedInterviewInd(intId);
        setSelectedPromptInd(promId)
        setFormData(interviewDetails[intId].levels[promId])
        setShowForm(true)
        setIsUpdated(!isupdated)
    }

    const savePromptConfiguration = (obj) => {
        console.log("obj---------->", obj);
        setShowForm(false)

        const existvalues = interviewDetails;
        const existObj = existvalues[selectedinteviewInd]
        const existlevel = existObj.levels;
        obj.subQuestions = [];
        existlevel[selectedpromptInd] = obj;

        existObj.levels = existlevel
        existvalues[selectedinteviewInd] = existObj
        console.log("existvalues---------->", existvalues);
        setInterviewDetails(existvalues);
        setSelectedInterviewInd("");
        setSelectedPromptInd("");
        setIsUpdated(!isupdated)
    }

    const handleOpenQuesPopup = (intIndex, promptIndex, quesIndex) => {
        setSelectedInterviewInd(intIndex);
        setSelectedPromptInd(promptIndex);
        setSelectedQuesInd(quesIndex);
        setFormData(interviewDetails[intIndex].levels[promptIndex].subQuestions[quesIndex])

        setShowQuesForm(!showQuesForm)
    }

    const handleCloseQuesPopup = () => {
        setShowQuesForm(!showQuesForm)
    }

    const savePromptQuestions = (data) => {
        const existvalues = interviewDetails;
        const existObj = existvalues[selectedinteviewInd];
        const existlevel = existObj.levels;
        const existPrompt = existlevel[selectedpromptInd]
        const existSubQuestions = existPrompt.subQuestions
        existSubQuestions[selectedQuesInd] = data;
        existObj.levels = existlevel
        existvalues[selectedinteviewInd] = existObj
        console.log("AAAAAA---------->", existvalues);
        setInterviewDetails(existvalues);
        setSelectedInterviewInd("");
        setSelectedPromptInd("");
        setSelectedQuesInd("")
        setIsUpdated(!isupdated)
    }

    return (
        <div className='tw-p-10'>
            <div hidden={!loading}>
                <Loader />
            </div>
            <div className='tw-flex tw-text-blue-500 tw-mb-5 tw-font-extrabold'><a href='javascript:void(0);' onClick={() => window.history.back()} >{"<<Back"}</a></div>
            <InputForm showForm={showForm} handleCloseForm={handleCloseForm} savePromptConfiguration={savePromptConfiguration} formData={formData} />
            <QuestionPopup showForm={showQuesForm} handleCloseForm={handleCloseQuesPopup} savePromptQuestions={savePromptQuestions} formData={formData} />
            <Accordion defaultActiveKey="0">
                {interviewDetails.map((val, valindex) => {
                    return <Accordion.Item eventKey={valindex.toString()} key={valindex}>
                        <Accordion.Header>{val?.role}</Accordion.Header>

                        <Accordion.Body>
                            <div className="tw-bg-gray-100  tw-p-8 tw-overflow-auto">

                                <div className=" tw-space-y-8 tw-relative">

                                    <p className="tw-w-64 tw-bg-red-300 tw-text-center tw-py-4 tw-rounded tw-shadow tw-font-bold">Start the Interview</p>
                                    {/* down arrow */}
                                    <img src={downarrow} alt="" className='tw-w-10 tw-ml-24' />


                                    {val.levels.length !== 0 && val.levels.map((prompt, promptIndex) => {
                                        const { title, aiprompt, aiquestion } = prompt;
                                        return <main key={promptIndex} style={{ marginBottom: "10px" }} >

                                            <div className='tw-flex tw-items-center tw-mr-20'>

                                                {(typeof prompt === 'string' || prompt instanceof String)
                                                    ?
                                                    <div className='tw-relative'>
                                                        <p onClick={() => handleShowForm(valindex, promptIndex)} className=" tw-w-64 tw-bg-[#bfdbfe] tw-text-center tw-py-4 tw-rounded tw-shadow tw-font-bold tw-cursor-pointer">{prompt}</p>

                                                        <img onClick={() => console.log(23)} src={xmark} alt="" className='tw-absolute -tw-right-2 tw-h-7 -tw-top-1' />

                                                    </div>
                                                    :
                                                    <div className='tw-relative'>
                                                        <div onClick={() => handleShowForm(valindex, promptIndex)} class="tw-w-64 tw-min-w-64 tw-bg-blue-200 tw-text-center tw-py-4 tw-rounded tw-shadow tw-cursor-pointer " >
                                                            <h2 className='tw-font-bold'>{title}</h2>
                                                            <p><strong>Prompt: </strong>{aiprompt}</p>
                                                            {aiquestion && <p><strong>Question: </strong>{aiquestion}</p>}
                                                        </div>
                                                        <img onClick={() => console.log(23)} src={xmark} alt="" className='tw-absolute -tw-right-2 tw-h-7 -tw-top-1' />

                                                    </div>
                                                }



                                                {prompt && prompt.subQuestions && prompt.subQuestions.map((question, index) => {
                                                    const { promptques } = question;
                                                    return <div key={index} className='tw-flex tw-items-center '>
                                                        <img src={rightarrow} alt="" className='  tw-mx-16' />
                                                        {(typeof question === 'string' || question instanceof String) ?

                                                            <div className='tw-relative'>
                                                                <p onClick={() => handleOpenQuesPopup(valindex, promptIndex, index)} className=" tw-w-64  tw-min-w-64 tw-bg-[#bfdbfe] tw-text-center tw-py-4 tw-rounded tw-shadow tw-font-bold tw-cursor-pointer">{question}</p>

                                                                <img onClick={() => console.log(23)} src={xmark} alt="" className='tw-absolute -tw-right-2 tw-h-7 -tw-top-1' />

                                                            </div>
                                                            :

                                                            <div className='tw-relative'>
                                                                <p onClick={() => handleOpenQuesPopup(valindex, promptIndex, index)} className=" tw-w-64 tw-min-w-64 tw-text tw-h-[44%] tw-bg-[#bfdbfe] tw-text-center tw-py-4 tw-rounded tw-shadow tw-cursor-pointer">{promptques}</p>

                                                                <img onClick={() => console.log(23)} src={xmark} alt="" className='tw-absolute -tw-right-2 tw-h-7 -tw-top-1' />

                                                            </div>
                                                        }
                                                    </div>
                                                })}
                                                <button onClick={() => addQuestion(valindex, promptIndex)} className=' tw-ml-20 tw-pr-10 tw-text-blue-500'>Add+</button>
                                            </div>

                                            <img src={downarrow} alt="" className='tw-w-10 tw-mt-10 tw-ml-24' />
                                        </main>
                                    })}


                                    {/* {val.levels.length !== 0 && val.levels.map((question, index) => {
                                console.log("question---------->", question)
                                const { title, aiprompt, aiquestion } = question;
                                return <div className='tw-flex tw-flex-row' key={index} style={{ marginBottom: "10px" }} >
                                    <div>
                                        {(typeof question === 'string' || question instanceof String) ?
                                            <p onClick={() => handleShowForm(valindex, index)} className=" tw-w-64 tw-bg-[#bfdbfe] tw-text-center tw-py-4 tw-rounded tw-shadow tw-font-bold tw-cursor-pointer">{question}</p> :
                                            <div onClick={() => handleShowForm(valindex, index)} class="tw-w-64 tw-bg-blue-200 tw-text-center tw-py-4 tw-rounded tw-shadow tw-cursor-pointer" >
                                                <h2 className='tw-font-bold'>{title}</h2>
                                                <p><strong>Prompt: </strong>{aiprompt}</p>
                                                {aiquestion && <p><strong>Question: </strong>{aiquestion}</p>}
                                            </div>
                                        }
                                        <img src={downarrow} alt="" className='tw-mx-auto tw-mt-5' />
                                    </div>
                                    <button onClick={() => addQuestion(valindex, index)} className='tw-mt-2 tw-text-blue-500'>Add+</button>
                                    {question && question.subQuestions && question.subQuestions.map((quesval, quesIndex) => {
                                        const { promptques } = quesval;
                                        return <div className='tw-flex tw-flex-row'>
                                            <div>
                                                <img src={rightarrow} alt="" className='tw-mx-auto tw-mt-5' />
                                            </div>

                                            {(typeof quesval === 'string' || quesval instanceof String) ? <p onClick={() => handleOpenQuesPopup(valindex, index, quesIndex)} className=" tw-w-64 tw-h-[44%] tw-bg-[#bfdbfe] tw-text-center tw-py-4 tw-rounded tw-shadow tw-font-bold tw-cursor-pointer">{quesval}</p> :
                                                <div><p onClick={() => handleOpenQuesPopup(valindex, index, quesIndex)} className=" tw-w-64 tw-h-[44%] tw-bg-[#bfdbfe] tw-text-center tw-py-4 tw-rounded tw-shadow tw-font-bold tw-cursor-pointer">{promptques}</p></div>}

                                        </div>
                                    })}
                                </div>
                            })} */}
                                    <button onClick={() => addPrompt(valindex)} className='tw-mt-2 tw-text-blue-500 tw-ml-24'>Add+</button>


                                    {/* End the Interview */}
                                    <div className="tw-w-64 tw-bg-red-300 tw-text-center tw-py-4 tw-rounded tw-shadow tw-font-bold">End the Interview</div>

                                    <div className='tw-flex tw-justify-end tw-gap-x-5'>

                                        <button className='tw-border-2 tw-bg-blue-500 tw-text-white tw-px-5 tw-py-1 tw-rounded-md'>Save</button>
                                        <button className='tw-border-2 tw-bg-red-500 tw-text-white tw-px-5 tw-py-1 tw-rounded-md'>Cancel</button>
                                    </div>

                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                })}
            </Accordion>
        </div >
    )
}

export default Test