import React from 'react'

function AnalyzeReport(props) {
    const { analyzeReport } = props;
    const getFormattedString = (data) => {
        let check = data.split("\n");
        console.log("check------------------>", check)
        return check;
    }
    return (
        <div>
            {analyzeReport.length <= 3 && <div>
                <h1 class="tw-text-lg tw-font-normal tw-text-blue-600 lg:tw-text-base" data-tooltip-id="sentiment-tooltip">
                    Sentiment Analysis <i class="fa fa-question-circle"></i>:-
                </h1>
                {(analyzeReport.length !== 0 && analyzeReport[0]) && <p class="tw-text-sm tw-font-normal tw-text-gray-500 lg:tw-text-base">
                    {analyzeReport[0].text}
                </p>}
                <h1 class="tw-text-lg tw-font-normal tw-text-blue-600 lg:tw-text-base" data-tooltip-id="emotion-tooltip">
                    Emotion Analysis <i class="fa fa-question-circle"></i>:-
                </h1>
                {(analyzeReport.length !== 0 && analyzeReport[1]) && <p class="tw-text-lg tw-font-normal tw-text-gray-500 lg:tw-text-base">
                    {analyzeReport[1].text}
                </p>}
                {/* <h1 class="tw-text-lg tw-font-normal tw-text-blue-600 lg:tw-text-base">
                    Vocabulary Words:-
                </h1>
                {(analyzeReport.length !== 0 && analyzeReport[2]) && <p class="tw-text-lg tw-font-normal tw-text-gray-500 lg:tw-text-base">
                    {analyzeReport[2].text}
                </p>} */}
            </div>}
            {analyzeReport.length === 4 && <div>
                <h1 class="tw-text-lg tw-font-normal tw-text-blue-600 lg:tw-text-base" data-tooltip-id="sentiment-tooltip">
                    Overall Perfomance :-
                </h1>
                {(analyzeReport.length !== 0 && analyzeReport[0]) && <p class="tw-text-sm tw-font-normal tw-text-gray-500 lg:tw-text-base">
                    {analyzeReport[0].text}
                </p>}
                <h1 class="tw-text-lg tw-font-normal tw-text-blue-600 lg:tw-text-base" data-tooltip-id="emotion-tooltip">
                    Overview :-
                </h1>
                {(analyzeReport.length !== 0 && analyzeReport[1]) && <p class="tw-text-sm tw-font-normal tw-text-gray-500 lg:tw-text-base">
                    {analyzeReport[1].text}
                </p>}
                <h1 class="tw-text-lg tw-font-normal tw-text-blue-600 lg:tw-text-base">
                    Strengths:-
                </h1>
                {(analyzeReport.length !== 0 && analyzeReport[2]) && getFormattedString(analyzeReport[2].text).map((val, index) => {
                    return <p key={index} class="tw-text-lg tw-font-normal tw-text-gray-500 lg:tw-text-base">
                        {val}
                    </p>
                })}
                <h1 class="tw-text-lg tw-font-normal tw-text-blue-600 lg:tw-text-base">
                    Areas to Improve:-
                </h1>
                {(analyzeReport.length !== 0 && analyzeReport[3]) && getFormattedString(analyzeReport[3].text).map((val, index) => {
                    return <p key={index} class="tw-text-lg tw-font-normal tw-text-gray-500 lg:tw-text-base">
                        {val}
                    </p>
                })}
            </div>}
        </div>
    )
}

export default AnalyzeReport
