import React, { useState } from 'react'
import OTPInput from "otp-input-react"
import { Button } from 'react-bootstrap'
import Loader from '../../layout/Loader'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import TopTitleBar from '../../layout/TopTitleBar';



const Otp = () => {

    const [loading, setLoading] = useState(false);
    const [OTP, setOTP] = useState("");

    let location = useLocation()
    const navigate = useNavigate();

    const emailId = location.state && location.state.email
    const checkIfUserExists = location.state && location.state.checkIfUserExists

    const handleOtpVerification = async () => {
        try {
            setLoading(true)
            const res = await axios.post('https://api.jarsis.ai/user/verifyOtp', { email: emailId, otp: OTP });


            if (res.data.success === true) {

                if (checkIfUserExists) {

                    setLoading(false)
                    // Rest Password Page
                    navigate('/resetpassword', { state: { emailId } })

                }

            }
            else {
                setLoading(false)
                toast.error('Incorrect OTP')
            }
        }
        catch (error) {
            setLoading(false)
            toast.error(error.response.data.message)
        }
    }

    return (
        <>

            <div hidden={!loading} className='tw-bg-black tw-bg-opacity-50 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full'>
                <Loader />
            </div>

            <TopTitleBar />

            <div className=' tw-mt-20'>

                <h4 className="tw-text-2xl tw-mb-2 tw-text-center">Enter the verification code</h4>
                <p className="tw-mb-20 tw-w-[70%] lg:tw-w-[30%] tw-mx-auto tw-text-center">
                    An email containing a 4-digit verification code has been sent to the
                    email address - {emailId}
                </p>

                <div className="tw-flex tw-justify-center">
                    <OTPInput value={OTP} onChange={setOTP} inputClassName=" tw-border tw-border-blue-500  " className="tw-ml-10 " autoFocus OTPLength={4} otpType="number" disabled={false} />
                </div>

                <Button
                    variant="primary"
                    className=" tw-mx-auto tw-flex tw-mt-10 tw-px-20"
                    type="button"
                    disabled={OTP.length <= 3 || loading}
                    onClick={handleOtpVerification}
                >
                    Verify
                </Button>

            </div>


        </>
    )
}

export default Otp