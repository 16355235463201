import React from 'react'

const Loader = () => {
    return (
        <div className='tw-bg-black'>
            <div className="lds-roller tw-m-auto tw-absolute tw-translate-x-[-50%] tw-translate-y-[-50%]  tw-left-1/2 tw-top-1/2"><div className=""></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

export default Loader