import React, { useState } from 'react';
import downarrow from '../../img/downarrow.png';
import rightarrow from '../../img/rightarrow.png';
import xmark from '../../img/xmark.png';
import { useEffect } from 'react';
import InputForm from './inputForm';
import { toast } from 'react-toastify';
import QuestionPopup from './QuestionPopup';

const Configure = (props) => {
    const { levels, setLevels } = props;
    // const [levels, setLevels] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [formData, setFormData] = useState({ title: "", aiprompt: "", aiquestion: "" });
    const [selectedIndex, setSelectedIntex] = useState(0);
    const [selectedQuesIndex, setSelctedQuesIndex] = useState(0);
    const [showQuesForm, setShowQuesForm] = useState(false);

    useEffect(() => {
    }, [levels, isUpdate])

    const addPropmpts = () => {
        setLevels(prev => [...prev, `Prompt ${prev.length + 1}`])
    }

    const handleShowForm = (index) => {
        setSelectedIntex(index);
        setFormData(levels[index] ? levels[index] : formData)
        setShowPopup(!showPopup);
    }

    const handleCloseForm = () => {
        setShowPopup(!showPopup);
    }

    const savePromptConfiguration = (data) => {
        const existArray = levels;
        // existArray[selectedIndex] = data
        // setLevels(existArray);
        // setIsUpdate(!isUpdate)
        if (existArray[selectedIndex].subQuestions) {
            data.subQuestions = existArray[selectedIndex].subQuestions
        }
        handleCloseForm();
        setLevels(prelevel => {
            const existArray = prelevel;
            existArray[selectedIndex] = data
            return existArray
        })
    }

    const deletePrompt = (index) => {
        const existvalues = levels;
        //  const existObj = existvalues[index];
        existvalues.splice(index, 1);
        setLevels(existvalues);
        setIsUpdate(!isUpdate)
    }

    const addQuestion = (index) => {
        const currentArry = levels;
        if (typeof currentArry[index] === 'string' || currentArry[index] instanceof String) {
            toast.error("Please configure AI before add questions")
        } else {
            currentArry[index].subQuestions = currentArry[index].subQuestions ? currentArry[index].subQuestions : []
            currentArry[index].subQuestions.push(`Question ${currentArry[index].subQuestions.length + 1}`)
            setLevels(currentArry);
            setIsUpdate(!isUpdate)
        }

    };

    const handleOpenQuesPopup = (quesIndex, index) => {
        setSelectedIntex(index);
        setSelctedQuesIndex(quesIndex);
        setFormData(levels[index].subQuestions[quesIndex])
        setShowQuesForm(!showQuesForm);
    }

    const deleteSubQuestions = (prompIndex, quesIndex) => {
        const existvalues = levels;
        const existObj = existvalues[prompIndex];
        const existSubQuestions = existObj.subQuestions
        existSubQuestions.splice(quesIndex, 1)
        setLevels(existvalues);
        setIsUpdate(!isUpdate)
    }

    const handleCloseQuesPopup = () => {
        setShowQuesForm(!showQuesForm);
    }

    const savePromptQuestions = (data) => {
        console.log("quesData------------->", data)
        const existvalues = levels;
        const existObj = existvalues[selectedIndex];
        existObj.subQuestions[selectedQuesIndex] = data
        existvalues[selectedIndex] = existObj
        console.log("existvalues----------->", existvalues)


        // const existvalues = levels;
        // const existObj = existvalues[selectedIndex];
        // const existlevel = existObj.levels;
        // const existPrompt = existlevel[selectedpromptInd]
        // const existSubQuestions = existPrompt.subQuestions
        // existSubQuestions[selectedQuesInd] = data;
        // existObj.levels = existlevel
        // existvalues[selectedinteviewInd] = existObj
        // setInterviewDetails(existvalues);
        // setSelectedInterviewInd("");
        // setSelectedPromptInd("");
        // setSelectedQuesInd("")
        // setIsUpdated(!isupdated)
    }

    return (
        <div className="tw-bg-gray-100  tw-p-8 tw-overflow-auto">

            <InputForm showForm={showPopup} handleCloseForm={handleCloseForm} savePromptConfiguration={savePromptConfiguration} formData={formData} />
            <QuestionPopup showForm={showQuesForm} handleCloseForm={handleCloseQuesPopup} savePromptQuestions={savePromptQuestions} formData={formData} />

            <div className=" tw-space-y-8 tw-relative">

                <p className="tw-w-64 tw-bg-white tw-border tw-border-dotted tw-text-center tw-py-4 tw-rounded tw-shadow tw-font-light">Start the Interview</p>
                {/* down arrow */}
                <img src={downarrow} alt="" className='tw-w-10 tw-ml-24' />
                {levels.map((val, index) => {
                    const { title, aiprompt, aiquestion } = val;
                    return <main style={{ marginBottom: "10px" }} >

                        <div className='tw-flex tw-items-center tw-mr-20'>
                            {(typeof val === 'string' || val instanceof String)
                                ? <div className='tw-relative'>
                                    <p onClick={() => handleShowForm(index)} className=" tw-w-64 tw-bg-white tw-border tw-border-dotted tw-text-center tw-py-4 tw-rounded tw-shadow tw-font-thin tw-cursor-pointer tw-text-gray-600">{val}</p>

                                    <img src={xmark} alt="" className='tw-absolute tw-cursor-pointer -tw-right-2 tw-h-7 -tw-top-1' />

                                </div> :
                                <div className='tw-relative'>
                                    <div onClick={() => handleShowForm(index)} class="tw-w-64 tw-min-w-64 tw-bg-white tw-border tw-border-dotted tw-text-center tw-py-4 tw-rounded tw-shadow tw-cursor-pointer " >
                                        <h2 className='tw-font-bold'>{title}</h2>
                                        <p><strong>Prompt: </strong>{aiprompt}</p>
                                        {aiquestion && <p><strong>Question: </strong>{aiquestion}</p>}
                                    </div>
                                    <img onClick={() => deletePrompt(index)} src={xmark} alt="" className='tw-absolute tw-cursor-pointer -tw-right-2 tw-h-7 -tw-top-1' />

                                </div>}
                            {/* <img src={rightarrow} alt="" className='  tw-mx-16' /> */}
                            {val.subQuestions && val.subQuestions.map((question, valindex) => {
                                const { promptques } = question;
                                return <div key={index} className='tw-flex tw-items-center '>
                                    <img src={rightarrow} alt="" className='  tw-mx-16' />
                                    {(typeof question === 'string' || question instanceof String) ?

                                        <div className='tw-relative'>
                                            <p onClick={() => handleOpenQuesPopup(valindex, index)} className=" tw-w-64  tw-min-w-64 tw-bg-white tw-border tw-border-dotted  tw-text-center tw-py-4 tw-rounded tw-shadow tw-font-thin tw-cursor-pointer">{question}</p>

                                            <img onClick={() => deleteSubQuestions(index, valindex)} src={xmark} alt="" className='tw-absolute tw-cursor-pointer -tw-right-2 tw-h-7 -tw-top-1' />

                                        </div>
                                        :

                                        <div className='tw-relative'>
                                            <p onClick={() => handleOpenQuesPopup(valindex, index)} className=" tw-w-64 tw-min-w-64 tw-text tw-h-[44%] tw-bg-white tw-border tw-border-dotted  tw-text-center tw-py-4 tw-rounded tw-shadow tw-font-thin tw-cursor-pointer">{promptques}</p>

                                            <img onClick={() => deleteSubQuestions(index, valindex)} src={xmark} alt="" className='tw-absolute tw-cursor-pointer -tw-right-2 tw-h-7 -tw-top-1' />

                                        </div>
                                    }
                                </div>
                            })}
                            <button onClick={() => addQuestion(index)} className=' tw-ml-20 tw-pr-10 tw-text-blue-500'>Add+</button>
                        </div>
                        <img src={downarrow} alt="" className='tw-w-10 tw-ml-24 tw-mt-3' />
                    </main>
                })
                }

                <button onClick={() => addPropmpts()} className='tw-mt-2 tw-text-blue-500 tw-ml-24'>Add+</button>

                <div className="tw-w-64 tw-bg-white tw-border tw-border-dotted tw-text-center tw-py-4 tw-rounded tw-shadow tw-font-thin">End the Interview</div>
            </div>
        </div >
    )
}

export default Configure