import React, { useCallback, useState } from 'react';
import ReactFlow, {
    addEdge,
    useNodesState,
    useEdgesState,
    Controls,
    Background,
    MiniMap,
} from 'react-flow-renderer';

const initialNodes = [
    { id: 'start', type: 'input', data: { label: 'Start the Interview' }, position: { x: 250, y: 0 } },
    { id: 'end', type: 'output', data: { label: 'End the Interview' }, position: { x: 250, y: 400 } },
];

const initialEdges = [
    { id: 'start-end', source: 'start', target: 'end', animated: true },
];

const SequenceDiagram = () => {
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const [nodeId, setNodeId] = useState(1);

    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge(params, eds)),
        [setEdges]
    );

    const addNode = () => {
        const newNode = {
            id: `node-${nodeId}`,
            data: { label: `New Node ${nodeId}`, questions: [] },
            position: { x: 250, y: nodeId * 100 },
        };
        setNodeId((prev) => prev + 1);
        setNodes((nds) => [...nds, newNode]);
    };

    const deleteNode = (nodeId) => {
        setNodes((nds) => nds.filter((node) => node.id !== nodeId));
        setEdges((eds) => eds.filter((edge) => edge.source !== nodeId && edge.target !== nodeId));
    };

    const onEditNode = (id, newLabel) => {
        setNodes((nds) =>
            nds.map((node) =>
                node.id === id ? { ...node, data: { ...node.data, label: newLabel } } : node
            )
        );
    };

    const addQuestion = (id, question) => {
        setNodes((nds) =>
            nds.map((node) =>
                node.id === id
                    ? {
                        ...node,
                        data: {
                            ...node.data,
                            questions: [...(node.data.questions || []), question],
                        },
                    }
                    : node
            )
        );
    };

    return (
        <div style={{ height: 600 }}>
            <button onClick={addNode}>Add Node</button>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                fitView
            >
                <MiniMap />
                <Controls />
                <Background />
            </ReactFlow>
        </div>
    );
};

export default SequenceDiagram;
