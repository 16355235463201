import { useState } from "react";
import { version, getDocument, GlobalWorkerOptions } from "pdfjs-dist/build/pdf";
import nlp from "compromise";

// Set the worker source for pdf.js
GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.js`;

const ResumeParser = () => {
    const [name, setName] = useState("");

    const extractTextFromPDF = async (file) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = async () => {
            //console.log("reader.result---------->", reader.result)
            const pdf = await getDocument(new Uint8Array(reader.result)).promise;
            //console.log("reader.pdf---------->", pdf)
            let extractedText = "";

            for (let i = 1; i <= pdf.numPages; i++) {
                const page = await pdf.getPage(i);
                const textContent = await page.getTextContent();
                extractedText += textContent.items.map((item) => item.str).join(" ") + " ";
            }

            extractName(extractedText);
        };
    };

    const extractName = (text) => {
        //console.log("text---------->", text)
        const doc = nlp(text);
        const people = doc.people().out("array");
        const organization = doc.organizations().out("array");
        const nameRegex = /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/;
        const match = text.match(nameRegex);
        const email = doc.emails().text('normal')
        const phone = doc.phoneNumbers().out("array")
        console.log("people---------->", people)
        console.log("email---------->", email)
        console.log("phone---------->", phone)
        console.log("match---------->", match)
        setName(people.length > 0 ? people[0] : "Name not found");
    };

    return (
        <div className="p-4">
            <input
                type="file"
                accept="application/pdf"
                onChange={(e) => e.target.files.length && extractTextFromPDF(e.target.files[0])}
                className="border p-2"
            />
            <p className="mt-4">Extracted Name: {name}</p>
        </div>
    );
};

export default ResumeParser;
