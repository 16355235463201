import React from 'react'
import logo from '../img/new/Jarsis.ai.png'

const TopTitleBar = () => {
    return (
        <div className='tw-flex tw-gap-x-2 tw-items-center tw-justify-center tw-py-5 tw-pb-3 md:tw-pb-0' >
            <img src={logo} alt="" className='tw-w-10 lg:tw-w-8 ' />
            <p className='tw-font-[600] tw-text-lg lg:tw-text-sm'>jarsis.ai</p>
        </div>
    )
}

export default TopTitleBar