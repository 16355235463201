import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';

const Aiconfigurenew = () => {
    const [levels, setLevels] = useState(["level1"])
    const [isupdated, setIsUpdated] = useState(false);
    const [interviewDetails, setInterviewDetails] = useState([])

    useEffect(() => {
        console.log("interviewDetails------------>", interviewDetails)
    }, [levels, isupdated])

    useEffect(() => {

        const getInterviewDetails = async () => {
            const res = await axios.post('https://api.jarsis.ai/interviewQuestion/getAllInterviewQuestions', {}, { headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' } });
            console.log("res------------>", res)
            const { data } = res;
            const { payload } = data;
            const { interviewQuestionDetails } = payload;
            interviewQuestionDetails.map(val => {
                console.log("val------------>", val)
                const existvalues = interviewDetails;
                // existvalues[val?.role] = {}
                val.levels = [];
                existvalues.push(val)
                setInterviewDetails(existvalues)
                setIsUpdated(!isupdated)
            })
        }
        getInterviewDetails();

    }, [])

    const addLevels = (index) => {
        const count = interviewDetails[index].levels.length;
        console.log("count-------->", count)
        const existArray = interviewDetails;
        const existValues = existArray[index];
        existValues.levels.push("level" + (count + 1));
        console.log("count111-------->", existValues)
        existArray[index] = existValues
        setInterviewDetails(existArray);
        setIsUpdated(!isupdated)
    }
    return (
        <div className='tw-p-10'>


            <Accordion defaultActiveKey="0">
                {interviewDetails.map((val, index) => {
                    return <Accordion.Item eventKey={index.toString()} key={index}>
                        <Accordion.Header>{val?.role}</Accordion.Header>
                        <Accordion.Body>
                            <div class="tw-bg-gray-100 tw-flex tw-justify-center tw-items-center tw-p-8">

                                <div class="tw-flex tw-flex-col tw-items-center tw-space-y-8 tw-relative">
                                    <div class="tw-w-64 tw-bg-red-300 tw-text-center tw-py-4 tw-rounded tw-shadow tw-font-bold">Start the Interview</div>
                                    <div class="tw-h-8 tw-w-1 tw-bg-gray-500"></div>
                                    <div id="dialog2" class="triangle_down1"></div>

                                    <div class="tw-w-64 tw-bg-blue-200 tw-text-center tw-py-4 tw-rounded tw-shadow tw-cursor-pointer">
                                        <h2 className='tw-font-bold'>AI Prompt</h2>
                                        <p>{ }</p>
                                    </div>
                                    <div class="tw-h-8 tw-w-1 tw-bg-gray-500"></div>
                                    <div id="dialog2" class="triangle_down1"></div>

                                    <div class="tw-w-64 tw-bg-yellow-200 tw-text-center tw-py-4 tw-rounded tw-shadow tw-cursor-pointer">
                                        <h2 className='tw-font-bold'>Emotional Prompt</h2>
                                        <p>{ }</p>
                                    </div>
                                    <div class="tw-h-8 tw-w-1 tw-bg-gray-500"></div>
                                    <div id="dialog2" class="triangle_down1"></div>

                                    <div class="tw-w-64 tw-bg-blue-200 tw-text-center tw-py-4 tw-rounded tw-shadow tw-cursor-pointer">
                                        <h2 className='tw-font-bold'>Logical Prompt</h2>
                                        <p>{ }</p>
                                    </div>
                                    <div class="tw-h-8 tw-w-1 tw-bg-gray-500"></div>
                                    <div id="dialog2" class="triangle_down1"></div>

                                    <div class="tw-w-64 tw-bg-red-300 tw-text-center tw-py-4 tw-rounded tw-shadow tw-font-bold">End the Interview</div>

                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                })}
            </Accordion>
        </div >
    )
}

export default Aiconfigurenew