import React, { useEffect, useState } from 'react'
import SideNavBar from '../../layout/SideNavBar'
import Loader from '../../layout/Loader'
import filter from '../../img/new/filter.svg'
import search from '../../img/new/search.svg'

import AfterCallContent from './AfterCallContent'
import BeforeCallContent from './BeforeCallContent'
import axios from 'axios'

const PitchToDoctorAi = () => {

    const [loading, setLoading] = useState(false);
    const [doctorAIList, setDoctorAIList] = useState([]);
    const [doctorAIMain, setDoctorAIMain] = useState([]);

    const [searchInput, setSearchInput] = useState("");
    const [filteredItems, setFilteredItems] = useState('');

    function groupByTraineeName(data) {
        const grouped = {};

        data.forEach((val) => {
            const { _id, trainingDetails } = val;
            const traineeName = trainingDetails._id;
            if (!grouped[traineeName]) {
                grouped[traineeName] = { trainingDetails, names: [] };
            }
            grouped[traineeName].names.push(val);

        });

        return Object.values(grouped).map(val => val);
        //return grouped;
    }

    useEffect(() => {
        const getAllTrainings = async () => {
            try {
                setLoading(true);
                // const res = await axios.post('https://api.jarsis.ai/training/getAllTrainings', {}, {
                //     headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' }
                // })
                const res = await axios.post('https://api.jarsis.ai/interview/getAllInterviewsByEmail', { isTraining: true, emailId: `${localStorage.getItem("user_emailId")}` }, { headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' } })

                setLoading(false);
                if (res?.data?.success) {
                    const result = res?.data?.payload?.interviewDetails;

                    let trainingArray = [];
                    result.map(val => {
                        let trainigDetails = val?.trainingDetails;
                        trainingArray.push(trainigDetails);
                    })
                    //console.log("trainingArray------>", groupByTraineeName(result))

                    // var out = trainingArray.reduce(function (p, c) {
                    //     if (!p.some(function (el) { return el._id === c._id; })) p.push(c);
                    //     return p;
                    // }, []);

                    // console.log("trainingArray11111------>", out)
                    const finalValue = groupByTraineeName(result);
                    setDoctorAIList(finalValue)
                    setFilteredItems(finalValue)

                    setDoctorAIMain(finalValue[0])


                }
            } catch (error) {

            }


        }
        getAllTrainings();
    }, [])


    const handleSelectdoctorAI = (data) => {
        console.log(data)

        setDoctorAIMain(data)
        if (window.innerWidth <= 767) {
            window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
        }
    }

    // Function to handle search
    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();

        setSearchInput(value);

        const filtered = doctorAIList.filter((item) => {
            // Check `trainingDetails.name` and `trainingDetails.role`
            const trainingDetailsMatch =
                item.trainingDetails.name?.toLowerCase().includes(value) ||
                item.trainingDetails.role?.toLowerCase().includes(value);

            // Check `trainingDetails.character` array
            const characterMatch =
                item.trainingDetails.character &&
                item.trainingDetails.character.some((char) =>
                    char.title.toLowerCase().includes(value)
                );

            // Combine conditions
            return trainingDetailsMatch || characterMatch;
        });

        setDoctorAIMain(filtered[0])
        setFilteredItems(filtered);
    };

    return (
        <>
            <div hidden={!loading} className='tw-bg-black tw-bg-opacity-20 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-40'>
                <Loader />
            </div>



            <main className='tw-bg-[#fdfdff] lg:tw-flex tw-gap-x-10 poppins lg:tw-mt-6 lg:tw-mx-6 tw-items-start tw-mb-20'>

                {/* left side menu bar */}

                <SideNavBar />

                {/* right side content - 1 */}

                <section className='lg:tw-w-[80%] md:tw-flex tw-px-5 tw-pt-5 lg:tw-px-0 lg:tw-pt-0 tw-gap-x-5 '>

                    <main className="md:tw-w-[25%] tw-border tw-rounded-3xl tw-mb-5 md:tw-mb-0">
                        {/* 1st section */}
                        <section className='tw-flex tw-justify-between tw-items-center tw-border-b tw-p-5'>
                            <p className="tw-font-medium tw-text- tw-text-gray-700">Al Trainers</p>
                        </section>

                        {/* 2nd section */}
                        <section className='tw-px-4 tw-py-5 tw-flex tw-gap-x-3 tw-border-b'>
                            {/* <div className='tw-flex tw-items-center tw-p-2 tw-cursor-pointer tw-border tw-rounded-lg tw-gap-x-2 tw-w-1/2'>
                                <img src={filter} alt="" className='tw-w-5' />
                                <p>Divisions</p>
                            </div> */}

                            <div className='tw-flex tw-justify-between tw-items-center tw-cursor-pointer tw-border tw-rounded-lg tw-gap-x-2 tw-px-2 tw-w-[100%]'>
                                <input type="text" placeholder='Search' value={searchInput}
                                    onChange={handleSearch}
                                    className='tw-outline-none tw-w-full tw-py-2' />
                                <img src={search} alt="" />
                            </div>
                        </section>

                        {/* 3rd section */}
                        <section className='tw-pb-10'>
                            {filteredItems && filteredItems.map((item, index) => (
                                <main onClick={() => handleSelectdoctorAI(item)} key={index} className='tw-flex tw-flex-col xl:tw-flex-row xl:tw-items-center tw-px-3 xl:tw-px-5 tw-gap-x-5 tw-py-5 tw-border-b tw-cursor-pointer'>
                                    <div>
                                        <img src={item.trainingDetails.aiImage} alt="" className='tw-w-52 md:tw-w-16 md:tw-min-w-16 tw-mx-auto' />
                                    </div>

                                    <div className='tw-mt-2 xl:tw-mt-0'>
                                        <p>{item.trainingDetails.name}</p>
                                        <p className='tw-text-[#6d6d6d] tw-text-[10px]'>{item.trainingDetails.role}</p>

                                        <div className='tw-flex tw-gap-x-2 tw-mt-2'>
                                            {item?.trainingDetails.character.length !== 0 && item?.trainingDetails.character.map((charVal, cindex) => {

                                                return cindex < 2 && <p key={"char" + cindex} className='tw-flex tw-items-center tw-border tw-rounded-sm tw-px-1'>
                                                    <span className={`tw-h-[7px] tw-w-[7px] tw-inline-block tw-mr-2 tw-rounded-[50%] ${cindex % 2 ? "tw-bg-red-600" : "tw-bg-green-600"}`} ></span>
                                                    <span className='tw-text-[10px]'>{charVal.title}</span>
                                                </p>
                                            })}

                                            {item?.trainingDetails.character.length > 2 && <p className='tw-border tw-rounded-sm tw-px-1 tw-text-[#6d6d6d] tw-text-xs'>+2</p>}
                                        </div>

                                    </div>
                                </main>
                            ))}


                        </section>


                    </main>

                    {/* right side content - 2 */}

                    <BeforeCallContent doctorAIMain={doctorAIMain} />

                    {/* <AfterCallContent /> */}


                </section>

            </main >
        </>
    )
}

export default PitchToDoctorAi