import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const D = () => {


    const [activeIndex, setActiveIndex] = useState(null);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex(Math.floor(Math.random() * 4)); // Select a random index (0-3)
        }, 50); // Change ball every 2s

        return () => clearInterval(interval);
    }, []);
    return (

        <>


            {/* listen */}
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-min-h-screen tw-bg-gray-900">
            <motion.div
                className="tw-w-40 tw-h-40 tw-bg-blue-500 tw-rounded-full"
                animate={{
                    boxShadow: [
                        "0 0 10px rgba(0, 153, 255, 0.5)",
                        "0 0 20px rgba(0, 153, 255, 0.7)",
                        "0 0 30px rgba(0, 153, 255, 0.9)",
                        "0 0 20px rgba(0, 153, 255, 0.7)",
                        "0 0 10px rgba(0, 153, 255, 0.5)",
                    ],
                    scale: [1, 1.2, 1, 1.2, 1], // Instant zoom-in & zoom-out
                }}
                transition={{
                    boxShadow: {
                        duration: 1.5,
                        repeat: Infinity,
                        repeatType: "reverse",
                        ease: "easeInOut",
                    },
                    scale: {
                        duration: 5, // No smooth zoom (instant change)
                        repeat: Infinity,
                        repeatType: "reverse",
                        ease: "linear",
                    },
                }}
            />
        </div>


            {/* thinking */}
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-min-h-screen tw-bg-gray-900">
                <motion.div
                    className="tw-relative tw-w-64 tw-h-40 tw-flex tw-items-center tw-justify-center"
                >
                    <AnimatePresence>

                        <div className="tw-flex tw-gap-4">
                            {[0, 1, 2, 3].map((index) => (
                                <motion.div
                                    key={index}
                                    className="tw-w-16 tw-h-16 tw-bg-blue-500 tw-rounded-full"
                                    initial={{ scale: 0 }}
                                    animate={{
                                        scale: 1,
                                        //   y: [0, -5, 5, -5, 0], 
                                        //   height: ["4rem", "5rem", "4rem"], 
                                        //   width: ["7rem", "5rem", "6rem"], 
                                        //   rotate: [0, 5, -5, 5, -5, 0],
                                        transition: {
                                            duration: 0.5,
                                            ease: "easeInOut",
                                            delay: index * 0.1,
                                            repeat: Infinity,
                                            repeatType: "reverse"
                                        }
                                    }}
                                    exit={{ scale: 0 }}
                                />
                            ))}
                        </div>
                    </AnimatePresence>
                </motion.div>


            </div>

            {/* speak */}
            <div className="tw-flex tw-gap-4 tw-items-center tw-justify-center tw-min-h-screen tw-bg-gray-900">
                {[0, 1, 2, 3].map((index) => (
                    <motion.div
                        key={index}
                        className="tw-w-[64px] tw-bg-blue-500 tw-rounded-full"
                        animate={{
                            height: activeIndex === index ? 180 : 64,
                            x: activeIndex === index ? [0, -4, 4, -4, 4, 0] : 0, // Vibrate effect
                        }}
                        transition={{
                            height: { duration: 0.2, ease: "easeInOut" },
                            x: { duration: 0.15, repeat: Infinity }, // Reduced by 50ms
                        }}
                        style={{ width: 64 }}
                    />
                ))}
            </div>





        </>

    );
};




export default D