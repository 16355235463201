import React, { useEffect, useState, useRef } from 'react';
import { MDBDataTable } from 'mdbreact';
import SideNavBar from '../../layout/SideNavBar';
import Loader from '../../layout/Loader';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';
import AnalyzeReport from '../analysis/AnalyzeReport';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import AudioConversation from '../candidateList/AudioConversation';

const TraineeList = () => {

    const data = {
        columns: [
            {
                label: "S. No",
                field: "sno",
                sort: "asc",
                width: 50,
            },
            {
                label: "Name",
                field: "name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Role",
                field: "role",
                sort: "asc",
                width: 200,
            },
            // {
            //     label: "Status",
            //     field: "status",
            //     sort: "asc",
            //     width: 100,
            // },
            {
                label: "Report",
                field: "report",
                sort: "asc",
                width: 100,
            },
        ],
        rows: [],
    };


    const [candidateTraineeList, setCandidateTraineeList] = useState(data);
    const [loading, setLoading] = useState(false);
    const [showanalysis, setshowAnalysis] = useState(false);
    const [analyzeReport, setAnalyzeReport] = useState([]);
    const [rinterviewDetails, setrInterviewDetails] = useState({})
    const [showAudioChats, setShowAudioChats] = useState(false);
    const toastId = React.useRef(null);

    const getStatus = (val) => {
        let element = <div className=''>
            <span className="tw-h-[10px] tw-w-[10px] tw-inline-block tw-mr-2 tw-rounded-[50%] tw-bg-[#ffc107]"></span>
            <span className="">Scheduled</span>
        </div>;
        if (val.isStarted && val.isCompleted) {
            element = <div className=''>
                <span className="tw-h-[10px] tw-w-[10px] tw-inline-block tw-mr-2 tw-rounded-[50%] tw-bg-green-600"></span>
                <span className="">Completed</span>
            </div>;
        }
        if (val.isStarted && !val.isCompleted) {
            element = <div className=''>
                <span className="tw-h-[10px] tw-w-[10px] tw-inline-block tw-mr-2 tw-rounded-[50%] tw-bg-red-600"></span>
                <span className="">Incomplete</span>
            </div>;
        }
        return element;
    }



    useEffect(() => {

        const fetchData = async () => {
            try {
                setLoading(true);
                const res = await axios.post('https://api.jarsis.ai/interview/getAllInterviewsByEmail', { isTraining: true, emailId: `${localStorage.getItem("user_emailId")}` }, { headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' } })

                if (res.data.success && res?.data?.payload?.interviewDetails.length !== 0) {
                    console.log("res----------->", res);

                    const details = res?.data?.payload?.interviewDetails;
                    console.log('details)', details);

                    const rows = details.map((val, index) => ({
                        sno: index + 1,
                        name: val?.trainingDetails?.name ? val?.trainingDetails?.name : "",
                        role: val?.trainingDetails?.role ? val?.trainingDetails?.role : "",
                        // status: getStatus(val),
                        report: val.messages.length !== 0 || val.reports.length !== 0 ? <a onClick={() => viewAnalyzeReport(val?._id)} className='tw-text-blue-400' href='javascript:void(0);'>View Analysis</a> : <p className='tw-text-black'>No Chat History</p>,
                    }));

                    setCandidateTraineeList((prevState) => ({
                        ...prevState,
                        rows,
                    }));

                } else {
                    setCandidateTraineeList([]);
                }
                setLoading(false);
            } catch (e) {
                setLoading(false);
                setCandidateTraineeList([])
            }

        }
        fetchData();

    }, [])

    const viewAnalyzeReport = async (interviewId) => {

        try {
            setLoading(true);
            //toast.info("Please wait, Generating report.....")
            toastId.current = toast.info("Please wait, Generating report.....");
            const res = await axios.post('https://api.jarsis.ai/analysis/getAudioAnalysisByInterviewId', { "interviewId": interviewId }, { headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' } })
            setTimeout(() => {
                toast.dismiss(toastId.current)
            }, 1000);

            if (res.data.success) {
                setshowAnalysis(true);
                const { payload } = res?.data;
                setAnalyzeReport(payload?.result)
                setrInterviewDetails(payload?.interviewDetails);
            } else {
                if (res.status === 350) {
                    toast.error("Sorry,No Chat history")
                } else {
                    toast.error("Sorry,Can't generate report");
                }
                setshowAnalysis(false);
                setAnalyzeReport([]);
                setrInterviewDetails({})
            }
            setLoading(false);
        } catch (e) {
            toast.error("Sorry,Please try again");
            setLoading(false);
            setAnalyzeReport([])
            setshowAnalysis(false);
        }
    }

    return (
        <>
            <div hidden={!loading} className='tw-bg-black tw-bg-opacity-20 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-40'>
                <Loader />
            </div>
            <main className='tw-bg-[#fdfdff] lg:tw-flex tw-gap-x-10 poppins lg:tw-mt-6 lg:tw-mx-6 tw-items-start tw-mb-20'>

                {/* left side menu bar */}

                <SideNavBar />

                {/* right side - main content */}
                <section className="lg:tw-w-[80%] tw-border tw-rounded-xl tw-p-5">
                    <div className='tw-flex tw-justify-between tw-items-center tw-my-5'>
                        <p className="tw-font-medium tw-text-lg tw-text-gray-700">Trainee List</p>
                        {/* <button className='tw-text-white tw-bg-black tw-rounded-lg tw-py-2 tw-px-4'>Schedule a Train/ing</button> */}
                    </div>
                    <MDBDataTable
                        data={candidateTraineeList}
                        searching={true}
                        pagination={true}
                        noBottomColumns
                        responsive
                        striped
                        small
                        className="tw-rounded-lg tw-z-0"
                    />
                </section>

                <Modal show={false} onHide={() => setshowAnalysis(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Analyze Report</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <AnalyzeReport analyzeReport={analyzeReport} />
                            <h1 class="tw-text-lg tw-font-normal tw-text-blue-600 lg:tw-text-xl dark:tw-text-gray-400">
                                Audio:-
                            </h1>
                            {(rinterviewDetails.isAudioCreationStarted && rinterviewDetails.isAudioCreationCompleted) && <div>

                                {/* <audio src={rinterviewDetails?.interviewAudio} controls /> */}
                                <a href="javascript:void(0);" onClick={() => setShowAudioChats(true)} class="tw-font-medium tw-text-orange-500 dark:tw-text-blue-500 hover:tw-underline">Conversation History</a>
                            </div>}
                            {(!rinterviewDetails.isAudioCreationCompleted) && <p class="tw-text-lg tw-font-normal tw-text-gray-500 lg:tw-text-xl dark:tw-text-gray-400">
                                Processing......
                            </p>}
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setshowAnalysis(false)}>
                            Close
                        </Button>
                        {/* <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button> */}
                    </Modal.Footer>
                </Modal>
                {showAudioChats && <AudioConversation showAudioChats={showAudioChats} setShowAudioChats={setShowAudioChats} newaudioData={rinterviewDetails?.interviewAudioSeparate} candidateName={rinterviewDetails?.candidateName} />}
                <SlidingPane
                    className="tw-w-[40%] tw-z-50"
                    overlayClassName="some-custom-overlay-class"
                    isOpen={showanalysis}
                    title="Analysis Report"
                    subtitle=""
                    onRequestClose={() => {
                        // triggered on "<" on left top click or on outside click
                        setshowAnalysis(!showanalysis)
                    }}
                >
                    {/* <div>
                        <AnalyzeReport analyzeReport={analyzeReport} />
                        <h1 class="tw-text-lg tw-font-normal tw-text-blue-600 lg:tw-text-xl dark:tw-text-gray-400">
                            Audio:-
                        </h1>
                        {(rinterviewDetails.isAudioCreationStarted && rinterviewDetails.isAudioCreationCompleted) && <div>

                            
                            <a href="javascript:void(0);" onClick={() => setShowAudioChats(true)} class="tw-font-medium tw-text-orange-500 dark:tw-text-blue-500 hover:tw-underline">Conversation History</a>
                        </div>}
                        {(!rinterviewDetails.isAudioCreationCompleted) && <p class="tw-text-lg tw-font-normal tw-text-gray-500 lg:tw-text-xl dark:tw-text-gray-400">
                            Processing......
                        </p>}
                    </div> */}

                    <div class="tw-grid tw-grid-cols-4 tw-gap-1">
                        <div className='tw-border-2 tw-border-solid tw-border-gray-400 tw-justify-center tw-p-3'>
                            <p><strong className='tw-text-[#9b5feb]'>Talk/Listen Ratio</strong></p>
                            <p>{analyzeReport?.talk_listen_ratio?.value}</p>
                            <p> <span className={`tw-h-[7px] tw-w-[7px] tw-inline-block tw-mr-2 tw-rounded-[50%] ${analyzeReport?.talk_listen_ratio?.status !== 'good' ? "tw-bg-red-600" : "tw-bg-green-600"}`} ></span> {analyzeReport?.talk_listen_ratio?.status}</p>
                        </div>
                        <div className='tw-border-2 tw-border-solid tw-border-gray-400 tw-justify-center tw-p-3'>
                            <p><strong className='tw-text-[#9b5feb]'>Filler Words</strong></p>
                            <p>{analyzeReport?.filler_words?.value}</p>
                            <p> <span className={`tw-h-[7px] tw-w-[7px] tw-inline-block tw-mr-2 tw-rounded-[50%] ${analyzeReport?.filler_words?.status !== 'good' ? "tw-bg-red-600" : "tw-bg-green-600"}`} ></span> {analyzeReport?.filler_words?.status}</p>
                        </div>
                        <div className='tw-border-2 tw-border-solid tw-border-gray-400 tw-justify-center tw-p-3'>
                            <p><strong className='tw-text-[#9b5feb]'>Talk Speed</strong></p>
                            <p>{analyzeReport?.talkSpeed?.value}</p>
                            <p> <span className={`tw-h-[7px] tw-w-[7px] tw-inline-block tw-mr-2 tw-rounded-[50%] ${analyzeReport?.talkSpeed?.status !== 'good' ? "tw-bg-red-600" : "tw-bg-green-600"}`} ></span> {analyzeReport?.talkSpeed?.status}</p>
                        </div>
                        <div className='tw-border-2 tw-border-solid tw-border-gray-400 tw-justify-center tw-p-3'>
                            <p><strong className='tw-text-[#9b5feb]'>Monologue</strong></p>
                            <p>{analyzeReport?.longestMonologue?.value}</p>
                            <p> <span className={`tw-h-[7px] tw-w-[7px] tw-inline-block tw-mr-2 tw-rounded-[50%] ${analyzeReport?.longestMonologue?.status !== 'good' ? "tw-bg-red-600" : "tw-bg-green-600"}`} ></span> {analyzeReport?.longestMonologue?.status}</p>
                        </div>
                    </div>
                </SlidingPane>
            </main >
        </>
    )
}

export default TraineeList