import React, { useEffect, useState } from 'react'
import arrow from '../../img/new/backArrow.svg'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import SideNavBar from './SideNavBar';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useSearchParams } from "react-router-dom";
import Loader from '../../layout/Loader';







const Chart = () => {
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    console.log(id);
    const [loading, setLoading] = useState(false);
    const [timeLogs, setTimeLogs] = useState([]);

    useEffect(() => {

        const fetchData = async () => {
            try {
                setLoading(true);
                const res = await axios.post('https://api.jarsis.ai/interview/getInterviewDetails', { interviewId: id }, { headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' } })
                const timeLogsData = res?.data?.payload.interviewDetails.timeLogs;
                console.log(timeLogsData);
                setTimeLogs(timeLogsData)


                setLoading(false);
            } catch (e) {
                setLoading(false);
            }

        }

        fetchData();

    }, [])

    const data = [];

    timeLogs && timeLogs.map((val, index) => (
        data.push(
            {   
                "name": "Human Message : " + val.humanMessage + ` \n Ai response : ` + val.aiResponse,
                'Silence Threshold': val.silenceThreshold,
                'Added Message To Thread': val.addedMessageToThread,
                'Got AI Stream': val.gotAIStream,
                'Completed Getting Ai Stream': val.completedGettingAiStream,
                'Generated Speech': val.generatedSpeech,
                'Converted To Wav': val.convertedToWav,
                'Base64 Encoding': val.base64Encoding,
                'Complete Api Response': val.completeApiResponse
            })

    ))

    console.log(data);

    const values = data.map(item => item["Complete Api Response"]);
    const maxValue = Math.max(...values);
    const minValue = Math.min(...values);
    const avgValue = Math.round(values.reduce((sum, val) => sum + val, 0) / values.length);

    console.log(maxValue);


    return (
        <>
            <div hidden={!loading} className='tw-bg-black tw-bg-opacity-20 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-40'>
                <Loader />
            </div>


            <main className='tw-bg-[#fdfdff] lg:tw-flex tw-gap-x-10 poppins lg:tw-mt-6 lg:tw-mx-6 tw-items-start tw-mb-20'>

                {/* left side menu bar */}

                <SideNavBar />

                {/* right side - main content */}
                <section className="lg:tw-w-[80%] tw-border tw-rounded-xl tw-p-5">
                    <Link to={'/super_admin/interviews'} className='tw-flex tw-items-center tw-my-5 tw-gap-2 tw-w-fit'>
                        <img src={arrow} alt="" />
                        <p className="tw-font-medium tw-text-lg ">Back</p>
                    </Link>

                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name"/>
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="Silence Threshold" stackId="a" fill="#8884d8" />
                            <Bar dataKey="Added Message To Thread" stackId="a" fill="#82ca9d" />
                            <Bar dataKey="Got AI Stream" stackId="a" fill="#ffc658" />
                            <Bar dataKey="Completed Getting Ai Stream" stackId="a" fill="#ff8042" />
                            <Bar dataKey="Generated Speech" stackId="a" fill="#8dd1e1" />
                            <Bar dataKey="Converted To Wav" stackId="a" fill="#a4de6c" />
                            <Bar dataKey="Base64 Encoding" stackId="a" fill="#d0ed57" />
                            <Bar dataKey="Complete Api Response" stackId="a" fill="#ffb6c1" />

                        </BarChart>
                    </ResponsiveContainer>

                    <div className='tw-flex tw-mt-10 tw-justify-center tw-gap-10'>
                        <p>Highest Value : {maxValue}</p>
                        <p>Average Value : {avgValue}</p>
                        <p>Lowest Value : {minValue}</p>
                    </div>
                </section>



            </main >
        </>

    )
}

export default Chart